import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useAppDispatch } from 'store/hooks';
import {
  UsersListLoadingSelector,
  UsersListMetaSelector,
  UsersListSelector,
  usersThunks,
} from 'store/slices/users.slice';
import { useSelector } from 'react-redux';
import { User } from 'interfaces';
import { Box, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { formatInTimeZone } from 'date-fns-tz';
import UserDialog from 'components/Dialogs/UserDialog/UserDialog';
import { SortOrder } from 'enums/sort-order.enum';

const UsersPage = () => {
  const dispatch = useAppDispatch();
  const users = useSelector(UsersListSelector);
  const usersMeta = useSelector(UsersListMetaSelector);
  const usersLoading = useSelector(UsersListLoadingSelector);
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<undefined | string>(undefined);
  const [pagination, setPagination] = useState({
    order: SortOrder.ASC,
    orderName: 'username',
    page: 1,
    take: 10,
  });

  const openUserDetails = (userId?: string) => {
    setSelectedUserId(userId);
    setOpen(true);
  };

  const onPageSizeChange = (size: number) => {
    setPagination((paginationOptions) => ({ ...paginationOptions, take: size }));
  };

  const onPageChange = (page: number) => {
    setPagination((paginationOptions) => ({ ...paginationOptions, page: page + 1 }));
  };

  const onSortChange = (sortModel: GridSortModel) => {
    if (sortModel.length > 0 && sortModel[0].sort && sortModel[0].field) {
      const order = sortModel[0].sort.toUpperCase() as SortOrder;
      const orderName = sortModel[0].field;
      setPagination((paginationOptions) => ({ ...paginationOptions, order, orderName }));
    }
  };

  const onCancelClick = () => {
    setOpen(false);
    dispatch(usersThunks.getUsers(pagination));
  };

  useEffect(() => {
    dispatch(usersThunks.getUsers(pagination));
  }, [pagination]);

  const columnsDefinition: GridColDef[] = [
    {
      field: 'username',
      headerName: 'Username',
      flex: 10,
      disableColumnMenu: true,
    },
    {
      field: 'updatedAt',
      headerName: 'Last Modified',
      flex: 2,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => `${formatInTimeZone(params.row.updatedAt, 'Europe/Amsterdam', 'dd-MM-yyyy hh:mm')}`,
    },
  ];

  return (
    <>
      <Box>
        <Box
          display='flex'
          flexDirection='row-reverse'
        >
          <Button
            onClick={() => openUserDetails()}
            variant='contained'
          >
            Add new user
          </Button>
        </Box>
        <Typography
          variant='h6'
          margin={1}
          textAlign='center'
        >
          Users
        </Typography>
        <Box>
          <DataGrid
            rows={users}
            columns={columnsDefinition}
            rowCount={usersMeta.itemCount}
            autoHeight
            hideFooterSelectedRowCount
            loading={usersLoading}
            onRowClick={(params: GridRowParams<User>) => openUserDetails(params.row.id)}
            onPageSizeChange={onPageSizeChange}
            onSortModelChange={onSortChange}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            onPageChange={onPageChange}
            pageSize={pagination.take}
            paginationMode='server'
          />
        </Box>
      </Box>
      {open && (
        <UserDialog
          onCancelClick={onCancelClick}
          open={open}
          id={selectedUserId}
        />
      )}
    </>

  );
};

export default UsersPage;
