import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  LoadingCompaniesSelector,
  CompaniesListSelector,
  companiesThunks,
} from 'store/slices/companies.slice';
import { useAppDispatch } from 'store/hooks';
import {
  Button, Box, Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { formatInTimeZone } from 'date-fns-tz';
import CompanyDialog from 'components/Dialogs/CompanyDialog/CompanyDialog';
import { Company } from 'interfaces/company.interfaces';

const CompaniesPage = () => {
  const dispatch = useAppDispatch();
  const companies = useSelector(CompaniesListSelector);
  const companiesLoading = useSelector(LoadingCompaniesSelector);
  const [open, setOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<undefined | string>(undefined);

  const openCompanyDetails = (companyId?: string) => {
    setSelectedCompanyId(companyId);
    setOpen(true);
  };

  const onCancelClick = () => {
    setOpen(false);
    dispatch(companiesThunks.getCompanies());
  };

  useEffect(() => {
    dispatch(companiesThunks.getCompanies());
  }, []);

  const columnsDefinition: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 8,
      disableColumnMenu: true,
    },
    {
      field: 'code',
      headerName: 'Code',
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: 'updatedAt',
      headerName: 'Last Modified',
      flex: 2,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => `${formatInTimeZone(params.row.updatedAt, 'Europe/Amsterdam', 'dd-MM-yyyy hh:mm')}`,
    },
  ];

  return (
    <>
      <Box>
        <Box
          display='flex'
          flexDirection='row-reverse'
        >
          <Button
            onClick={() => openCompanyDetails()}
            variant='contained'
          >
            Add new company
          </Button>
        </Box>
        <Typography
          variant='h6'
          margin={1}
          textAlign='center'
        >
          Companies
        </Typography>
        <Box>
          <DataGrid
            rows={companies}
            columns={columnsDefinition}
            autoHeight
            hideFooterSelectedRowCount
            loading={companiesLoading}
            onRowClick={(params: GridRowParams<Company>) => openCompanyDetails(params.row.id)}
          />
        </Box>
      </Box>
      {open && (
        <CompanyDialog
          onCancelClick={onCancelClick}
          open={open}
          id={selectedCompanyId}
        />
      )}
    </>
  );
};

export default CompaniesPage;
