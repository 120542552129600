import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert, AlertColor } from '@mui/material';

interface CustomAlertProps {
  open: boolean;
  message: string | null;
  durationMs: number;
  verticalPosition: 'top' | 'bottom';
  horizontalPosition: 'left' | 'center' | 'right';
  severity?: AlertColor;
  onClose: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  open,
  message,
  durationMs,
  verticalPosition,
  horizontalPosition,
  severity,
  onClose,
}) => (
  <Snackbar
    open={open}
    autoHideDuration={durationMs}
    anchorOrigin={{
      vertical: verticalPosition,
      horizontal: horizontalPosition,
    }}
    onClose={onClose}
  >
    <Alert
      severity={severity || 'success'}
      variant='filled'
    >
      {message}
    </Alert>
  </Snackbar>
);

export default CustomAlert;
