import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import { Box, Typography, TextField } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridSortModel,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import PublishIcon from '@mui/icons-material/Publish';
import DownloadIcon from '@mui/icons-material/Download';
import TemplateDialog from 'components/Dialogs/TemplateDialog/TemplateDialog';
import {
  TemplatesListSelector,
  TemplatesLoadingSelector,
  TemplatesMetaSelector,
  templatesThunks,
} from 'store/slices/templates.slice';
import { Template } from 'interfaces';
import { formatDateTime } from 'helpers/formaters';
import { exportImportService } from 'services/export-import.service';
import UploadFileDialog from 'components/Dialogs/UploadFileDialog/UploadFileDialog';
import CustomAlert from 'components/CustomAlert/CustomAlert';
import LoadingButton from '@mui/lab/LoadingButton';
import { errorActions, ErrorSelector } from 'store/slices/error.slice';
import { SortOrder } from 'enums/sort-order.enum';

const TemplatesPage = () => {
  const dispatch = useAppDispatch();
  const templates = useSelector(TemplatesListSelector);
  const meta = useSelector(TemplatesMetaSelector);
  const templatesLoading = useSelector(TemplatesLoadingSelector);
  const error = useSelector(ErrorSelector);
  const [pagination, setPagination] = useState({
    order: SortOrder.DESC,
    orderName: 'updatedAt',
    page: 1,
    take: 10,
    searchValue: '',
  });
  const [open, setOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<undefined | string>(undefined);

  const [openUploadFileDialog, setOpenUploadFileDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [exporting, setExporting] = useState(false);

  const getTemplates = () => {
    dispatch(templatesThunks.getTemplates(pagination));
  };

  const openTemplateDetails = (templateId?: string) => {
    setSelectedTemplateId(templateId);
    setOpen(true);
  };

  const onPageSizeChange = (size: number) => {
    setPagination((paginationOptions) => ({ ...paginationOptions, take: size }));
  };

  const onPageChange = (page: number) => {
    setPagination((paginationOptions) => ({ ...paginationOptions, page: page + 1 }));
  };

  const onSearch = (e: any) => {
    const searchValue = e.target.value;
    setPagination((paginationOptions) => ({ ...paginationOptions, searchValue }));
  };

  const onSortChange = (sortModel: GridSortModel) => {
    if (sortModel.length > 0 && sortModel[0].sort && sortModel[0].field) {
      const order = sortModel[0].sort.toUpperCase() as SortOrder;
      const orderName = sortModel[0].field;
      setPagination((paginationOptions) => ({ ...paginationOptions, order, orderName }));
    }
  };

  const onCancelClick = () => {
    setOpen(false);
    dispatch(templatesThunks.getTemplates(pagination));
  };

  useEffect(() => {
    if (error && error.errorOccured === true && error.errorStatus === 404) {
      setSelectedTemplateId(undefined);
      setOpen(false);
      getTemplates();
    }
  }, [error]);

  useEffect(() => {
    getTemplates();
  }, [pagination]);

  const exportTemplates = async (): Promise<void> => {
    setExporting(true);

    try {
      const fileContent = await exportImportService.export();
      const blob = new Blob([JSON.stringify(fileContent)], {
        type: 'application/json',
      });
      const link = document.createElement('a');
      link.download = `Export-${Date.now()}.json`;
      link.href = window.URL.createObjectURL(blob);
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      link.dispatchEvent(clickEvent);
      link.remove();
    } catch (err: any) {
      dispatch(errorActions.setError({
        message: err.response.data.message,
        errorStatus: err.response.status,
      }));
    } finally {
      setExporting(false);
    }
  };

  const columnsDefinition: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 6,
      disableColumnMenu: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'url',
      headerName: 'URL',
      flex: 4,
      disableColumnMenu: true,
    },
    {
      field: 'updatedAt',
      headerName: 'Last Modified',
      flex: 2,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams<Template>) => formatDateTime(params.row.updatedAt),
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Last Modified By',
      flex: 2,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams<Template>) => params.row.lastUpdatedBy?.username || '-',
      sortable: false,
    },
  ];

  return (
    <>
      <Box
        display='flex'
        flexDirection='row-reverse'
        justifyContent='space-between'
      >
        <Button
          onClick={() => openTemplateDetails()}
          variant='contained'
        >
          Add new template
        </Button>
        <Box
          display='flex'
          gap='15px'
        >
          <LoadingButton
            onClick={() => exportTemplates()}
            variant='outlined'
            loading={exporting}
            startIcon={<PublishIcon />}
          >
            Export
          </LoadingButton>
          <Button
            onClick={() => setOpenUploadFileDialog(true)}
            variant='outlined'
            startIcon={<DownloadIcon />}
          >
            Import
          </Button>
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='space-between'
        width='50%'
        flexWrap='wrap'
        mt='2rem'
      >
        <TextField
          size='medium'
          variant='standard'
          label='Search...'
          onChange={onSearch}
        />
        <Typography
          variant='h6'
          margin={1}
          textAlign='center'
          mt={2}
        >
          Templates
        </Typography>
      </Box>
      <Box>
        <DataGrid
          rows={templates}
          columns={columnsDefinition}
          rowCount={meta.itemCount}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onPageChange={onPageChange}
          onRowClick={(params: GridRowParams) => openTemplateDetails(params.row.id)}
          autoHeight
          hideFooterSelectedRowCount
          loading={templatesLoading}
          onPageSizeChange={onPageSizeChange}
          paginationMode='server'
          onSortModelChange={onSortChange}
          pageSize={pagination.take}
        />
      </Box>
      {open && (
        <TemplateDialog
          onCancelClick={onCancelClick}
          open={open}
          id={selectedTemplateId}
        />
      )}
      {openUploadFileDialog && (
        <UploadFileDialog
          open={openUploadFileDialog}
          onClose={(importDone: boolean) => {
            setOpenUploadFileDialog(false);

            if (importDone) {
              dispatch(templatesThunks.getTemplates(pagination));
              setOpenAlert(true);
            }
          }}
        />
      )}
      <CustomAlert
        open={openAlert}
        message='Templates were sucessfully imported!'
        durationMs={2000}
        severity='success'
        verticalPosition='bottom'
        horizontalPosition='center'
        onClose={() => setOpenAlert(false)}
      />
    </>

  );
};

export default TemplatesPage;
