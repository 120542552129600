import React from 'react';
import { Typography, Button, TextField } from '@mui/material';
import Box from '@mui/material/Box/Box';

type LogsToolbarProps = {
  onRefresh: () => void;
  onClear: () => void;
  filterValue: string;
  onFilter: (val: string) => void;
}

const LogsToolbar: React.FC<LogsToolbarProps> = (props: LogsToolbarProps) => (
  <Box
    display='flex'
    justifyContent='space-between'
    width='100%'
    flexWrap='wrap'
    alignItems='center'
    position='relative'
    paddingTop='3rem'
    pb={1}
  >
    <Typography
      variant='h6'
      textAlign='center'
      width='100%'
      position='absolute'
    >
      Logs
    </Typography>
    <TextField
      label='Search...'
      size='medium'
      variant='standard'
      value={props.filterValue}
      onChange={(event: any) => props.onFilter(event.target.value)}
    />
    <Box
      display='flex'
      gap={1}
    >
      <Button
        size='medium'
        variant='contained'
        onClick={props.onRefresh}
      >
        Refresh
      </Button>
      <Button
        size='medium'
        variant='contained'
        color='error'
        onClick={props.onClear}
      >
        Clear logs
      </Button>
    </Box>
  </Box>
);

export default LogsToolbar;
