import { Api } from 'constants/urls';
import { DecryptedJwtToken, UserInfo } from 'interfaces';
import { restService } from './rest.service';

class AuthService {
  async login(username: string, password: string) {
    return restService.doPost<UserInfo>(Api.LOGIN, {
      username,
      password,
    });
  }

  setJwt(jwt: string) {
    localStorage.setItem('jwt', jwt);
  }

  async autoLogin(): Promise<DecryptedJwtToken> {
    return restService.doPost<DecryptedJwtToken>(Api.AUTO_LOGIN);
  }

  getJwt(): string | null {
    return localStorage.getItem('jwt');
  }

  clearJwt(): void {
    return localStorage.removeItem('jwt');
  }
}

export const authService = new AuthService();
