import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import { Box, Button } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { MockCallsLogsListSelector, LogsLoadingSelector, logThunks } from 'store/slices/logs.slice';
import { useEffect, useState } from 'react';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { MockCallLog } from 'interfaces/log.interfaces';
import MockCallsLogDialog from 'components/Dialogs/LogDialogs/MockCallsLogsDialog/MockCallsLogDialog';
import { formatDateTime } from 'helpers/formaters';
import LogsToolbar from './LogsToolbar';

const MockCallsLogs = () => {
  const dispatch = useAppDispatch();
  const logsLoading = useSelector(LogsLoadingSelector);
  const logsList = useSelector(MockCallsLogsListSelector);
  const [filteredLogs, setFilteredLogs] = useState<MockCallLog[]>([]);
  const [filteredValue, setFilteredValue] = useState<string>('');
  const [logDetailsOpen, setLogDetailsOpen] = useState<boolean>(false);
  const [selectedLogs, setSelectedLogs] = useState<MockCallLog>();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState<number>(10);

  const onViewClick = (logDetails: MockCallLog) => {
    setLogDetailsOpen(true);
    setSelectedLogs(logDetails);
  };

  const onFilter = (inputValue: string) => {
    setFilteredValue(inputValue);
    const filterValue = inputValue.toLowerCase();
    if (!inputValue) setFilteredLogs(logsList);
    else {
      setFilteredLogs(logsList.filter((log) => {
        if (formatDateTime(log.date.toString()).toLowerCase().includes(filterValue)
          || log.statusCode.toString().toLowerCase().includes(filterValue)
          || log.template?.toString().toLowerCase().includes(filterValue)) return log;

        return false;
      }));
    }
  };

  const columnsDefinition: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Entry date/time',
      flex: 6,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => formatDateTime(params.row.date),
    },
    {
      field: 'statusCode',
      headerName: 'Status',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'template',
      headerName: 'Name',
      flex: 6,
      disableColumnMenu: true,
    },
    {
      field: 'id',
      headerName: 'Action',
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          variant='contained'
          size='small'
          onClick={() => onViewClick(params.row)}
        >
          View
        </Button>
      ),
    },
  ];

  const clearLogs = () => {
    setConfirmationModalOpen(false);
    dispatch(logThunks.clearMockCallsLogs());
  };

  const retrieveLogs = () => {
    dispatch(logThunks.getMockCallsLogs());
  };

  useEffect(() => {
    setFilteredLogs(logsList);
  }, [logsList]);

  useEffect(() => {
    retrieveLogs();
  }, []);

  return (
    <>
      <LogsToolbar
        onFilter={onFilter}
        filterValue={filteredValue}
        onClear={() => setConfirmationModalOpen(true)}
        onRefresh={retrieveLogs}
      />

      <Box>
        <DataGrid
          rows={filteredLogs}
          columns={columnsDefinition}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          autoHeight
          hideFooterSelectedRowCount
          loading={logsLoading}
          getRowId={(row: MockCallLog) => row.id}
          paginationMode='client'
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
      </Box>

      {logDetailsOpen && selectedLogs && (
        <MockCallsLogDialog
          logDetails={selectedLogs}
          onCancelClick={() => setLogDetailsOpen(false)}
        />
      )}

      <ConfirmationModal
        open={confirmationModalOpen}
        message={`This action cannot be undone. 
                  Are you sure you want to delete all logs ?`}
        onConfirm={clearLogs}
        onCancel={() => setConfirmationModalOpen(false)}
      />
    </>

  );
};

export default MockCallsLogs;
