import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ReactJson from 'react-json-view-with-toggle';

interface MockCallsLogDetailsProps {
  details: any;
}

enum DataTypes {
  FORMATTED = 'FORMATTED',
  RAW = 'RAW',
}

const MockCallsLogDetails: React.FC<MockCallsLogDetailsProps> = ({ details }) => {
  const [dataType, setDataType] = useState<DataTypes>(DataTypes.RAW);

  return (
    <Box>
      <Box sx={{
        display: 'flex', gap: 2, p: 2,
      }}
      >
        <Button
          variant='contained'
          onClick={() => setDataType(DataTypes.RAW)}
        >
          Raw Data
        </Button>
        <Button
          variant='contained'
          onClick={() => setDataType(DataTypes.FORMATTED)}
        >
          Formatted Data
        </Button>
      </Box>

      <Box sx={{
        p: 2,
        background: '#fbfbfb',
        border: '1px solid #ddd',
        wordBreak: 'break-word',
        minHeight: 250,
      }}
      >
        {dataType === DataTypes.FORMATTED ? (
          <ReactJson
            src={details}
            name={null}
            collapsed
            enableClipboard={false}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              whiteSpace: 'pre-wrap',
            }}
          />
        ) : (
          <Typography sx={{
            background: '#fbfbfb',
            p: 2,
          }}
          >
            {JSON.stringify(details)}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MockCallsLogDetails;
