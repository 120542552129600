import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState, Template, TemplatesState } from 'interfaces';
import { PaginationResponse, PaginationOptions } from 'interfaces/pagination.interface';
import { templatesService } from 'services/templates.service';
import { AppThunk, CustomDispatchType } from 'store';
import * as errorActions from 'store/slices/error.slice';

export const templatesState: TemplatesState = {
  list: [],
  loading: false,
  pagination: {
    page: 0,
    take: 0,
    itemCount: 0,
    pageCount: 0,
    itemsPerPage: 0,
  },
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState: templatesState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTemplates: (state, action: PayloadAction<{ data: Template[], meta: PaginationResponse }>) => {
      state.list = action.payload.data;
      state.pagination = action.payload.meta;
    },
    clearTemplates: (state) => {
      state.list = [];
      state.pagination = templatesState.pagination;
    },
  },
});

const getTemplates = (paginationOptions: PaginationOptions): AppThunk => async (dispatch: CustomDispatchType) => {
  dispatch(templatesListActions.setLoading(true));
  dispatch(templatesListActions.clearTemplates());

  try {
    const response = await templatesService.getList(paginationOptions);

    dispatch(templatesListActions.setTemplates(response));
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(templatesListActions.setLoading(false));
  }
};

export const templatesThunks = {
  getTemplates,
};

export const TemplatesListSelector = (state: AppState) => state.templates.list;
export const TemplatesMetaSelector = (state: AppState) => state.templates.pagination;
export const TemplatesLoadingSelector = (state: AppState) => state.templates.loading;

export const templatesListActions = templatesSlice.actions;

export default templatesSlice.reducer;
