import axios from 'axios';

class RestService {
  async doGet<T>(url: string, config = {}): Promise<T> {
    const response = await axios.get<T>(url, config);

    return response.data;
  }

  async doPost<T>(url: string, data: any = {}, config = {}): Promise<T> {
    const response = await axios.post<T>(url, data, config);

    return response.data;
  }

  async doPut<T>(url: string, data: any, config = {}): Promise<T> {
    const response = await axios.put<T>(url, data, config);

    return response.data;
  }

  async doDelete<T>(url: string, config = {}): Promise<T> {
    const response = await axios.delete<T>(url, config);

    return response.data;
  }
}

export const restService = new RestService();
