import React from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-xml-doc';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-ejs';
import 'prismjs/themes/prism.css';
import styles from './CodeEditor.module.scss';

interface CodeEditorProps {
  editorName: string;
  value: string;
  required?: boolean;
  language: 'js' | 'xml';
  onValueChange: (e:any) => void;
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  editorName, value, onValueChange, required, language,
}) => {
  const hightlightWithLineNumbers = (input: string, language: string) =>
    highlight(input, languages.ejs, 'ejs')
      .split('\n')
      .map((line: any, i: any) => `<span class=${styles.editorLineNumber}>${i + 1}</span>${line}`)
      .join('\n');

  return (
    <Editor
      name={editorName}
      textareaId={styles.codeArea}
      className={styles.editor}
      value={value}
      insertSpaces
      tabSize={2}
      onValueChange={onValueChange}
      highlight={(value: string) => hightlightWithLineNumbers(value, language)}
      required={required}
      padding={4}
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 12,
        minHeight: '100%',
      }}
    />
  );
};

export default CodeEditor;
