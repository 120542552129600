import React from 'react';
import {
  Dialog, DialogContent,
} from '@mui/material';
import { ErrorLog } from 'interfaces/log.interfaces';
import CustomDialogTitle from '../CustomDialogTitle/CustomDialogTitle';

interface ErrorLogDialogProps {
  onCancelClick: () => void;
  logDetails: ErrorLog;
}

const ErrorLogDialog: React.FC<ErrorLogDialogProps> = ({ logDetails, onCancelClick }) => (
  <Dialog
    open
    fullScreen
    sx={{
      '& .title': {
        color: '#d32f2f',
      },
    }}
  >
    <CustomDialogTitle
      title={logDetails.message}
      onCancelClick={onCancelClick}
      className='title'
    />
    <DialogContent
      dividers
      sx={{ display: 'flex' }}
    >
      {logDetails.details}
    </DialogContent>
  </Dialog>
);

export default ErrorLogDialog;
