import React from 'react';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Box
      hidden={value !== index}
      style={{ width: '100%' }}
      {...other}
      pl={3}
    >
      {children}
    </Box>
  );
};

export default TabPanel;
