import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { AuthSelector } from 'store/slices/auth.slice';

interface RoleGuardProps {
  requiredRole: string;
  redirectLink: string;
  children: ReactElement;
}

const RoleGuard: React.FC<RoleGuardProps> = ({
  requiredRole,
  redirectLink,
  children,
}) => {
  const authData = useSelector(AuthSelector);

  return authData.role === requiredRole
    ? children
    : (
      <Navigate
        to={redirectLink}
        replace
      />
    );
};

export default RoleGuard;
