import { ContentCopy } from '@mui/icons-material';
import {
  Box, IconButton, InputAdornment, Tooltip,
} from '@mui/material';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CustomAlert from 'components/CustomAlert/CustomAlert';
import copy from 'copy-to-clipboard';
import { RequestMethods } from 'enums/request-methods.enum';
import { TemplateTypes } from 'enums/template-types.enum';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AuthSelector } from 'store/slices/auth.slice';

interface TemplateInfoProps {
  templateName: string;
  templateUrl: string;
  templateType: TemplateTypes;
  templateRequestMethod?: RequestMethods;
  templateSoapAction?: string;
  setTemplateName: (value: string) => any;
  setTemplateUrl: (value: string) => any;
  setTemplateType: (value: TemplateTypes) => any;
  setTemplateRequestMethod: (value: RequestMethods) => any;
  setSoapAction: (value: string) => any;
}

const TemplateInfo: React.FC<TemplateInfoProps> = ({
  templateName,
  templateUrl,
  templateType,
  templateRequestMethod,
  templateSoapAction,
  setTemplateName,
  setTemplateUrl,
  setTemplateType,
  setTemplateRequestMethod,
  setSoapAction,
}) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const authData = useSelector(AuthSelector);
  const [callUrl, setCallUrl] = useState(
    `${window.location.protocol}//${window.location.host}/api/mock-api/${templateType.toLowerCase()}/${authData.companyCode}${templateUrl}`,
  );

  useEffect(() => {
    setCallUrl(
      `${window.location.protocol}//${window.location.host}/api/mock-api/${templateType.toLowerCase()}/${authData.companyCode}${templateUrl}`,
    );
  }, [templateUrl]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='15px'
    >
      <CustomInput
        id='name'
        fieldName='name'
        value={templateName}
        fieldLabel='Template name'
        required
        onChange={(event: any) => setTemplateName(event.target.value)}
      />
      <CustomInput
        id='url'
        fieldName='url'
        value={templateUrl}
        fieldLabel='Template url'
        required
        onChange={(event: any) => setTemplateUrl(event.target.value)}
        endAdornment={(
          <InputAdornment position='end'>
            <Tooltip
              title={callUrl}
              placement='top'
            >
              <IconButton
                size='small'
                onClick={() => {
                  copy(callUrl);
                  setAlertOpen(true);
                }}
              >
                <ContentCopy fontSize='small' />
              </IconButton>
            </Tooltip>
          </InputAdornment>
      )}
      />
      <CustomSelect
        selectName='type'
        value={templateType}
        selectLabel='Type'
        selectMap={Object.entries(TemplateTypes).map(([key, value]) => ({
          label: key,
          value,
        }))}
        onChange={(event: any) => setTemplateType(event.target.value)}
      />
      {templateType === TemplateTypes.REST ? (
        <CustomSelect
          selectName='requestMethod'
          value={templateRequestMethod || RequestMethods.GET}
          selectLabel='Method'
          selectMap={Object.entries(RequestMethods).map(([key, value]) => ({
            label: key,
            value,
          }))}
          onChange={(event: any) => setTemplateRequestMethod(event.target.value)}
        />
      ) : (
        <CustomInput
          id='soap-action'
          fieldName='soapAction'
          value={templateSoapAction || ''}
          fieldLabel='Template soap action'
          onChange={(event: any) => setSoapAction(event.target.value)}
        />
      )}
      <CustomAlert
        open={alertOpen}
        verticalPosition='bottom'
        horizontalPosition='center'
        durationMs={2000}
        message={`Url ${callUrl} was sucessfully copied to clipboard!`}
        onClose={() => {
          setAlertOpen(false);
        }}
      />
    </Box>
  );
};

export default TemplateInfo;
