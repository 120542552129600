import { Box } from '@mui/material';
import Loading from 'components/Loading/Loading';
import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AuthSelector } from 'store/slices/auth.slice';

interface AuthGuardProps {
  children: ReactElement;
}

const AuthGuard: React.FC<AuthGuardProps> = ({
  children,
}) => {
  const authData = useSelector(AuthSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authData.isAuthenticated && !authData.loading) {
      navigate('/login');
    }
  }, [authData]);

  return authData.isAuthenticated && !authData.loading ? children : (
    <Box
      position='absolute'
      top='0'
      bottom='0'
      left='0'
      right='0'
    >
      <Loading />
    </Box>
  );
};

export default AuthGuard;
