import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import RateReviewIcon from '@mui/icons-material/RateReview';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ReactNode, useEffect, useState } from 'react';
import { AuthSelector, authThunks } from 'store/slices/auth.slice';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { useSelector } from 'react-redux';
import { UserRoles } from 'enums/user-roles.enum';
import { ConfigSelector } from 'store/slices/config.slice';
import classes from './Layout.module.scss';

const drawerWidth = 240;

type NavBarItem = {
  title: string;
  icon: any;
  link?: string;
  roles: UserRoles[];
};

type LayoutProps = {
  children: ReactNode;
};

const navBarItems: NavBarItem[] = [
  {
    title: 'Users',
    icon: <PeopleIcon />,
    link: '/users',
    roles: [UserRoles.GLOBAL_ADMIN],
  },
  {
    title: 'Companies',
    icon: <BusinessIcon />,
    link: '/companies',
    roles: [UserRoles.GLOBAL_ADMIN],
  },
  {
    title: 'Templates',
    icon: <RateReviewIcon />,
    link: '/templates',
    roles: [UserRoles.USER],
  },
];

const Layout = (props: LayoutProps) => {
  const dispatch = useAppDispatch();
  const authData = useSelector(AuthSelector);
  const config = useSelector(ConfigSelector);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [docsLink, setDocsLink] = useState<string | null>(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(navBarItems.find((item: NavBarItem) => item.link === window.location.pathname)?.title);

  useEffect(() => {
    setSelectedMenuItem(navBarItems.find((item: NavBarItem) => item.link === window.location.pathname)?.title);
  }, [window.location.pathname]);

  useEffect(() => {
    if (config.docsLink) {
      setDocsLink(config.docsLink);
    }

    if (config.enableLogs && navBarItems.findIndex((item) => item.title === 'Logs') === -1) {
      navBarItems.push({
        title: 'Logs',
        icon: <SaveAltIcon />,
        link: '/logs',
        roles: [UserRoles.USER, UserRoles.GLOBAL_ADMIN],
      });
    }
  }, [config]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const openDocumentation = () => {
    window.open(docsLink as string, '_blank', 'noreferrer');
  };

  const logOut = () => {
    dispatch(authThunks.logout());
  };

  const drawer = (
    <Box
      height='100%'
      display='flex'
      flexDirection='column'
      flexGrow='1'
    >
      <Toolbar>
        <Typography>
          {`Hi ${authData.username}`}
          !
        </Typography>
      </Toolbar>
      <Divider />
      <Box
        flex='1'
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
      >
        <List>
          {navBarItems.map((navbarItem) => {
            const isUserAllowed = navbarItem.roles.findIndex((role) => role === authData.role);
            if (isUserAllowed !== -1) {
              return (
                <ListItem
                  key={navbarItem.title}
                  disablePadding
                  onClick={() => setSelectedMenuItem(navbarItem.title)}
                >
                  <NavLink
                    to={navbarItem.link || '/'}
                    className={`${selectedMenuItem === navbarItem.title ? classes.selectedItem : ''} ${classes.menuItemLink}`}
                  >
                    <ListItemButton>
                      <ListItemIcon className={`${selectedMenuItem === navbarItem.title ? classes.selectedItem : ''}`}>{navbarItem.icon}</ListItemIcon>
                      <ListItemText primary={navbarItem.title} />
                    </ListItemButton>
                  </NavLink>
                </ListItem>
              );
            }

            return null;
          })}
        </List>
        <List>
          <ListItem
            onClick={() => openDocumentation()}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary='Help' />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={logOut}
          >
            <NavLink
              to='/login'
              className={classes.menuItemLink}
            >
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary='Logout' />
              </ListItemButton>
            </NavLink>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            display='flex'
            justifyContent='space-between'
            width='100%'
          >
            <Typography
              variant='h6'
              noWrap
              component='div'
            >
              API Mock Service
            </Typography>
            <Typography
              variant='h6'
              noWrap
              component='div'
            >
              {authData.company}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
};

export default Layout;
