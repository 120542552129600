import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
  Checkbox,
} from '@mui/material';
import Loading from 'components/Loading/Loading';
import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import AddIcon from '@mui/icons-material/Add';
import {
  animals,
  colors,
  uniqueNamesGenerator,
} from 'unique-names-generator';
import CustomAlert from 'components/CustomAlert/CustomAlert';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useAppDispatch } from 'store/hooks';
import { useSelector } from 'react-redux';
import {
  SelectedTemplateResponsesListSelector,
  SelectedTemplateResponsesLoadingSelector,
  mockResponseThunks,
  mockResponseActions,
} from 'store/slices/mock-response.slice';
import { MockResponseData } from 'interfaces/mock-response.interfaces';

interface TemplateResponsesProps {
  templateId: string;
  selectedResponseId: string | null;
  setSelectedResponseId: (value: string | null) => any;
  setSelectedResponseName: (value: string) => any;
  setSelectedResponseBody: (value: string) => any;
  setSelectedResponseActive: (value: boolean) => any;
  setSelectedResponseStatusCode: (value: number) => any;
}

const TemplateResponses: React.FC<TemplateResponsesProps> = ({
  templateId,
  selectedResponseId,
  setSelectedResponseId,
  setSelectedResponseName,
  setSelectedResponseBody,
  setSelectedResponseActive,
  setSelectedResponseStatusCode,
}) => {
  const dispatch = useAppDispatch();
  const fetchingResponses = useSelector(SelectedTemplateResponsesLoadingSelector);
  const responses = useSelector(SelectedTemplateResponsesListSelector);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [responseIdToDelete, setResponseIdToDelete] = useState<null | string>(null);

  useEffect(() => {
    dispatch(mockResponseThunks.fetchTemplateResponses(templateId));
  }, [templateId]);

  const createResponse = async (templateId: string) => {
    const responseName = uniqueNamesGenerator({
      dictionaries: [colors, animals],
      separator: ' ',
    });
    const createdResponse = await dispatch(mockResponseThunks.createTemplateResponse({
      body: '',
      statusCode: StatusCodes.OK,
      active: false,
      mockResponseName: responseName,
      templateId,
    }));

    if (createdResponse) {
      setAlertMessage(`Response ${responseName} was created!`);
      setAlertOpen(true);
      dispatch(mockResponseThunks.fetchTemplateResponses(templateId));
    }
  };

  const deleteResponse = async (responseId: string, templateId: string) => {
    if (responseId === selectedResponseId) {
      setSelectedResponseId(null);
      dispatch(mockResponseActions.clearSelectedResponse());
    }
    const response = await dispatch(mockResponseThunks.deleteTemplateResponse(responseId));

    if (response) {
      setAlertMessage(`Response ${response.mockResponseName} was deleted!`);
      setAlertOpen(true);
      dispatch(mockResponseThunks.fetchTemplateResponses(templateId));
    }
  };

  const checkIfResponseSelected = (mockResponseId: string): boolean => selectedResponseId !== null && (selectedResponseId === mockResponseId);

  const getConfirmationModalMessage = (responseName: string) => `You want to delete <b>${responseName}</b> response.\nThis action can't be undone.`;

  const defineResponseToShow = () => {
    let responseToShow: MockResponseData = responses[0];
    const activeResponseExist = responses.find((response) => response.active === true);
    const selectedResponseExist = responses.find((response) => response.id === selectedResponseId);
    responseToShow = selectedResponseExist ?? activeResponseExist ?? responseToShow;

    setSelectedResponseId(responseToShow.id);
    setSelectedResponseName(responseToShow.mockResponseName);
    setSelectedResponseBody(responseToShow.body);
    setSelectedResponseActive(responseToShow.active);
    setSelectedResponseStatusCode(responseToShow.statusCode);
  };

  useEffect(() => {
    if (responses.length > 0) {
      defineResponseToShow();
    }
  }, [responses]);

  const sortResponses = (a: MockResponseData, b: MockResponseData) => {
    if (a.mockResponseName > b.mockResponseName) {
      return 1;
    }
    if (a.mockResponseName < b.mockResponseName) {
      return -1;
    }
    return 0;
  };

  const responsesCopy = [...responses].sort(sortResponses);

  return (
    <>
      <List sx={{ height: '85%' }}>
        <ListSubheader sx={{ height: '15%' }}>Responses:</ListSubheader>
        {fetchingResponses ? (<Loading />) : templateId && responses.length > 0
          ? (
            <Box sx={{ overflowY: 'auto', height: '85%' }}>
              {responsesCopy.map((response) => (
                <Box key={response.id}>
                  <ListItem
                    disablePadding
                    secondaryAction={(
                      <IconButton
                        edge='end'
                        onClick={() => {
                          setResponseIdToDelete(response.id);
                          setConfirmationModalMessage(getConfirmationModalMessage(response.mockResponseName));
                          setConfirmationModalOpen(true);
                        }}
                      >
                        <RiDeleteBin6Line />
                      </IconButton>
                    )}
                  >
                    <ListItemButton
                      selected={checkIfResponseSelected(response.id)}
                      onClick={() => {
                        setSelectedResponseId(response.id);
                        setSelectedResponseName(response.mockResponseName);
                        setSelectedResponseBody(response.body);
                        setSelectedResponseActive(response.active);
                        setSelectedResponseStatusCode(response.statusCode);
                      }}
                    >
                      <ListItemText
                        primary={response.mockResponseName}
                        sx={{
                          overflow: 'hidden',
                        }}
                      />
                      <Box
                        sx={{
                          marginRight: '12px',
                        }}
                      >
                        <Checkbox
                          checked={response.active}
                          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                            event.preventDefault();
                            const active = !response.active;
                            const updatedResponse = {
                              ...response,
                              active,
                            };
                            await dispatch(mockResponseThunks.updateSelectedResponse(updatedResponse));
                            await dispatch(mockResponseThunks.fetchTemplateResponses(templateId));
                          }}
                        />
                      </Box>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </Box>
              ))}
              <ConfirmationModal
                open={confirmationModalOpen}
                message={confirmationModalMessage}
                onCancel={() => {
                  setConfirmationModalMessage('');
                  setConfirmationModalOpen(false);
                }}
                onConfirm={() => {
                  if (responseIdToDelete) {
                    deleteResponse(responseIdToDelete, templateId);
                    setConfirmationModalMessage('');
                    setResponseIdToDelete(null);
                    setConfirmationModalOpen(false);
                  }
                }}
              />
            </Box>
          )
          : (
            <ListItem>
              <Card
                variant='outlined'
                sx={{
                  width: '100%',
                }}
              >
                <ListItemText>
                  <Typography
                    align='center'
                    fontSize='12px'
                  >
                    Empty
                  </Typography>
                </ListItemText>
              </Card>
            </ListItem>
          )}
      </List>
      <Button
        onClick={() => createResponse(templateId)}
        startIcon={<AddIcon />}
        fullWidth
        sx={{ height: '15%', padding: '0 16px' }}
      >
        Add response
      </Button>
      <CustomAlert
        message={alertMessage}
        open={alertOpen}
        horizontalPosition='center'
        verticalPosition='bottom'
        durationMs={2000}
        onClose={() => {
          setAlertMessage('');
          setAlertOpen(false);
        }}
      />
    </>
  );
};

export default TemplateResponses;
