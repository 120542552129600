import { Close } from '@mui/icons-material';
import { DialogActions, Box, Button } from '@mui/material';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import dialogStyles from '../CustomDialog.module.scss';

interface CustomDialogActionsProps {
  onCloseClick: () => void;
  onDeleteClick: () => void;
  onConfirmationCancel: () => void;
  onConfirm: () => void;
  renderDeleteButton: boolean;
  confirmationModalOpen: boolean;
  confirmationModalMessage: string;
}

const CustomDialogActions: React.FC<CustomDialogActionsProps> = ({
  onCloseClick,
  onDeleteClick,
  onConfirmationCancel,
  onConfirm,
  renderDeleteButton,
  confirmationModalOpen,
  confirmationModalMessage,
}) => (
  <DialogActions
    className={dialogStyles['dialog-footer']}
  >
    <Box
      display='flex'
      flexDirection='row-reverse'
      justifyContent='space-between'
      width='100%'
      paddingLeft='14px'
      paddingRight='14px'
    >
      <Box
        display='flex'
        gap='10px'
      >
        <Button
          type='submit'
          startIcon={<SaveIcon />}
          variant='outlined'
        >
          Save
        </Button>
        <Button
          onClick={onCloseClick}
          startIcon={<Close />}
          variant='outlined'
        >
          Close
        </Button>
      </Box>
      {renderDeleteButton && (
        <>
          <Button
            onClick={onDeleteClick}
            variant='outlined'
            size='medium'
            color='error'
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>

          <ConfirmationModal
            open={confirmationModalOpen}
            message={confirmationModalMessage}
            onCancel={onConfirmationCancel}
            onConfirm={onConfirm}
          />
        </>
      )}
    </Box>
  </DialogActions>
);

export default CustomDialogActions;
