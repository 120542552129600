import React from 'react';
import { IconButton } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

interface CustomDialogTitleProps {
  title: string;
  onCancelClick: () => void;
  className?: any;
}

const CustomDialogTitle: React.FC<CustomDialogTitleProps> = ({ title, onCancelClick, className }) => (
  <DialogTitle
    className={className}
  >
    {title}
    <IconButton
      aria-label='close'
      onClick={onCancelClick}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>

);

export default CustomDialogTitle;
