import { Api } from 'constants/urls';
import { ConfigData } from 'store/slices/config.slice';
import { restService } from './rest.service';

class ConfigService {
  public async getConfig(): Promise<ConfigData> {
    return restService.doGet<ConfigData>(Api.CONFIG);
  }
}

export const configService = new ConfigService();
