import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorState, AppState } from 'interfaces';

export const errorState: ErrorState = {
  errorOccured: false,
  errorMessage: '',
  errorStatus: 200,
};

const errorSlice = createSlice({
  name: 'error',
  initialState: errorState,
  reducers: {
    setError: (state, action: PayloadAction<ErrorActionPayload>) => {
      state.errorMessage = action.payload.message;
      state.errorOccured = true;
      state.errorStatus = action.payload.errorStatus;
    },
    removeError: (state) => {
      state.errorOccured = false;
      state.errorMessage = '';
      state.errorStatus = 200;
    },
  },
});

export interface ErrorActionPayload {
  message: string;
  errorStatus: number;
}

export const {
  setError, removeError,
} = errorSlice.actions;

export const ErrorSelector = (state: AppState) => state.error;

export const errorActions = errorSlice.actions;

export default errorSlice.reducer;
