import { combineReducers } from 'redux';
import { AppState } from 'interfaces';
import authReducer from './auth.slice';
import usersReducer from './users.slice';
import templatesReducer from './templates.slice';
import companiesReducer from './companies.slice';
import companyReducer from './company.slice';
import errorReducer from './error.slice';
import templateReducer from './template.slice';
import mockResponseReducer from './mock-response.slice';
import userReducer from './user.slice';
import rolesReducer from './roles.slice';
import configReducer from './config.slice';
import logsReducer from './logs.slice';

const rootReducer = combineReducers<AppState>({
  auth: authReducer,
  templates: templatesReducer,
  template: templateReducer,
  mockResponse: mockResponseReducer,
  companies: companiesReducer,
  company: companyReducer,
  error: errorReducer,
  users: usersReducer,
  user: userReducer,
  roles: rolesReducer,
  config: configReducer,
  logs: logsReducer,
});

export default rootReducer;
