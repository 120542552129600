const api = process.env.REACT_APP_BASE_URL;

export const Api = {
  // AUTH
  LOGIN: `${api}/auth/login`,
  AUTO_LOGIN: `${api}/auth/auto-login`,

  // COMPANIES
  COMPANY: `${api}/company`,
  COMPANY_LIST: `${api}/company/list`,

  // TEMPLATES
  TEMPLATE: `${api}/template`,
  TEMPLATES_LIST: `${api}/template/list`,

  // MOCK RESPONSE
  MOCK_RESPONSE: `${api}/mock-response`,
  MOCK_RESPONSES_BY_TEMPLATE: `${api}/mock-response/by-template`,

  // USER
  USER: `${api}/user`,
  USERLIST: `${api}/user/list`,

  // ROLE
  ROLE_LIST: `${api}/role/list`,

  // CONFIG
  CONFIG: `${api}/config`,

  // Export / Import
  EXPORT: `${api}/export`,
  IMPORT: `${api}/import`,

  // LOGS
  MOCK_CALLS_LOGS: `${api}/logs/mock-calls`,
  MOCK_CALLS_LOGS_LIST: `${api}/logs/mock-calls/list`,
  ERROR_LOGS: `${api}/logs/error`,
  ERROR_LOGS_LIST: `${api}/logs/error/list`,
};
