import {
  Box,
  Button,
  Modal,
  Typography,
} from '@mui/material';
import React from 'react';
import parse from 'html-react-parser';

const modalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid rgb(25, 118, 210)',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

interface ConfirmationModalProps {
  open: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  message,
  onConfirm,
  onCancel,
}) => (
  <Modal
    open={open}
  >
    <Box sx={modalContentStyle}>
      <h2>Are you sure?</h2>
      <Box marginTop='10px'>
        <Typography>{parse(message)}</Typography>
      </Box>
      <Box
        display='flex'
        justifyContent='end'
        gap='10px'
        marginTop='30px'
      >
        <Button
          variant='outlined'
          color='error'
          onClick={() => onConfirm()}
        >
          Yes
        </Button>
        <Button
          variant='outlined'
          onClick={() => onCancel()}
        >
          No
        </Button>
      </Box>
    </Box>
  </Modal>
);

export default ConfirmationModal;
