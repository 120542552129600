import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompaniesState } from 'interfaces';
import { Company } from 'interfaces/company.interfaces';
import { companyService } from 'services/company.service';
import { AppState, AppThunk, CustomDispatchType } from 'store';
import * as errorActions from 'store/slices/error.slice';

const initialState: CompaniesState = {
  list: [],
  loading: false,
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanies: (state, action: PayloadAction<Company[]>) => {
      state.list = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

const { setCompanies, setLoading } = companiesSlice.actions;

const getCompanies = (): AppThunk => async (dispatch: CustomDispatchType) => {
  dispatch(setLoading(true));

  try {
    const companies = await companyService.getCompanies();
    dispatch(setCompanies(companies));
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(setLoading(false));
  }
};

export const companiesThunks = {
  getCompanies,
};

export const CompaniesSelector = (state: AppState) => state.companies;
export const CompaniesListSelector = (state: AppState) => state.companies.list;
export const LoadingCompaniesSelector = (state: AppState) => state.companies.loading;

export default companiesSlice.reducer;
