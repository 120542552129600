import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppState,
  CompanyState,
  User,
} from 'interfaces';
import { Company, NewCompanyDto } from 'interfaces/company.interfaces';
import { companyService } from 'services/company.service';
import { usersService } from 'services/users.service';
import { AppThunk, CustomDispatchType } from 'store';
import { errorActions } from './error.slice';

type CompanyData = Pick<CompanyState, 'selectedCompany' | 'companyUsers'>;

const initialState: CompanyState = {
  loading: false,
  selectedCompany: null,
  companyUsers: [],
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSelectedCompany: (state, action: PayloadAction<Company>) => {
      state.selectedCompany = action.payload;
    },
    setCompayUsers: (state, action: PayloadAction<User[]>) => {
      state.companyUsers = action.payload;
    },
    clearState: (state) => {
      state.selectedCompany = null;
      state.companyUsers = [];
    },
  },
});

const fetchCompanyData = (companyId: string): AppThunk<Promise<CompanyData | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(companyActions.setLoading(true));

  try {
    const company = await companyService.getById(companyId);
    const users = await usersService.getCompanyUsers(companyId);

    dispatch(companyActions.setSelectedCompany(company));
    dispatch(companyActions.setCompayUsers(users));
    return {
      selectedCompany: company,
      companyUsers: users,
    };
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(companyActions.setLoading(false));
  }

  return null;
};

export const createCompany = (newCompany: NewCompanyDto): AppThunk<Promise<Company | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(companyActions.setLoading(true));

  try {
    const company = await companyService.create(newCompany);
    dispatch(companyActions.setSelectedCompany(company));

    return company;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(companyActions.setLoading(false));
  }

  return null;
};

export const updateCompany = (companyData: Company): AppThunk<Promise<Company | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(companyActions.setLoading(true));

  try {
    const company = await companyService.update(companyData);
    dispatch(companyActions.setSelectedCompany(company));

    return company;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(companyActions.setLoading(false));
  }

  return null;
};

export const deleteCompany = (companyId: string): AppThunk<Promise<Company | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(companyActions.setLoading(true));

  try {
    const deletedCompany = await companyService.deleteById(companyId);

    dispatch(companyActions.clearState());

    return deletedCompany;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(companyActions.setLoading(false));
  }

  return null;
};

export const companyThunks = {
  fetchCompanyData,
  createCompany,
  updateCompany,
  deleteCompany,
};

export const CompanySelector = (state: AppState) => state.company;
export const CompanyDataSelector = (state: AppState) => ({
  selectedCompany: state.company.selectedCompany,
  companyUsers: state.company.companyUsers,
});
export const SelectedCompanySelector = (state: AppState) => state.company.selectedCompany;
export const LoadingCompanyDataSelector = (state: AppState) => state.company.loading;

export const companyActions = companySlice.actions;

export default companySlice.reducer;
