import { Api } from 'constants/urls';
import { Company, NewCompanyDto } from 'interfaces/company.interfaces';
import { restService } from './rest.service';

class CompanyService {
  async getCompanies(): Promise<Company[]> {
    return restService.doGet<Company[]>(Api.COMPANY_LIST);
  }

  async getById(companyId: string): Promise<Company> {
    return restService.doGet<Company>(`${Api.COMPANY}/${companyId}`);
  }

  async create(newCompany: NewCompanyDto): Promise<Company> {
    return restService.doPost<Company>(Api.COMPANY, newCompany);
  }

  async update(company: Company): Promise<Company> {
    return restService.doPut<Company>(`${Api.COMPANY}`, company);
  }

  async deleteById(id: string): Promise<Company> {
    return restService.doDelete<Company>(`${Api.COMPANY}/${id}`);
  }
}

export const companyService = new CompanyService();
