import { formatInTimeZone } from 'date-fns-tz';

export const formatDateTime = (value: string, timezone: string = 'Europe/Amsterdam') => {
  const parsedDate = Date.parse(value);

  // If passed value is not valid date time string we don't want to raise an error
  if (Number.isNaN(parsedDate)) {
    return '-';
  }

  return formatInTimeZone(value, timezone, 'dd-MM-yyyy hh:mm');
};
