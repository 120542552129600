import { useSelector } from 'react-redux';
import { AuthSelector } from 'store/slices/auth.slice';
import { UserRoles } from 'enums/user-roles.enum';
import ErrorLogs from './components/ErrorLogs';
import MockCallsLogs from './components/MockCallsLogs';

const LogsPage = () => {
  const authData = useSelector(AuthSelector);

  return (
    <>
      { authData.role === UserRoles.GLOBAL_ADMIN && <ErrorLogs /> }
      { authData.role === UserRoles.USER && <MockCallsLogs /> }
    </>
  );
};

export default LogsPage;
