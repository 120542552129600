import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppState, User, UsersState,
} from 'interfaces';
import { AppThunk, CustomDispatchType } from 'store';
import { usersService } from 'services/users.service';
import * as errorActions from 'store/slices/error.slice';
import { PaginationOptions, PaginationResponse } from 'interfaces/pagination.interface';

export const usersState: UsersState = {
  list: [],
  loading: false,
  pagination: {
    page: 0,
    take: 0,
    itemCount: 0,
    pageCount: 0,
    itemsPerPage: 0,
  },
};

const usersSlice = createSlice({
  name: 'users',
  initialState: usersState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUsers: (state, action: PayloadAction<{ data: User[], meta: PaginationResponse }>) => {
      state.list = action.payload.data;
      state.pagination = action.payload.meta;
    },
    clearUsers: (state) => {
      state.list = [];
    },
  },
});

const getUsers = (pagination: PaginationOptions): AppThunk => async (dispatch: CustomDispatchType) => {
  dispatch(usersActions.setLoading(true));
  dispatch(usersActions.clearUsers());

  try {
    const response = await usersService.getUsersList(pagination);

    dispatch(usersActions.setUsers(response));
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(usersActions.setLoading(false));
  }
};

export const usersThunks = {
  getUsers,
};

export const UsersSelector = (state: AppState) => state.users;
export const UsersListSelector = (state: AppState) => state.users.list;
export const UsersListMetaSelector = (state: AppState) => state.users.pagination;
export const UsersListLoadingSelector = (state: AppState) => state.users.loading;

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;
