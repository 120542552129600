import { Api } from 'constants/urls';
import { restService } from './rest.service';

class ExportImportService {
  public async export() {
    return restService.doGet(Api.EXPORT);
  }

  public async import(data: FormData) {
    return restService.doPost(Api.IMPORT, data);
  }
}

export const exportImportService = new ExportImportService();
