import {
  Box,
  Card,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import Loading from 'components/Loading/Loading';
import { Form, Formik, FormikProps } from 'formik';
import { User } from 'interfaces';
import { NewCompanyDto, Company } from 'interfaces/company.interfaces';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useAppDispatch,
} from 'store/hooks';
import {
  companyActions,
  CompanyDataSelector,
  companyThunks,
  LoadingCompanyDataSelector,
} from 'store/slices/company.slice';
import { ConfigSelector } from 'store/slices/config.slice';
import CustomAlert from 'components/CustomAlert/CustomAlert';
import CustomDialogTitle from '../CustomDialogTitle/CustomDialogTitle';
import CustomInput from '../../CustomInput/CustomInput';

import dialogStyles from '../CustomDialog.module.scss';
import CustomDialogActions from '../CustomDialogActions/CustomDialogActions';

interface CompanyDialogProps {
  id: string | undefined;
  onCancelClick: () => void;
  open: boolean;
}

const CompanyDialog: React.FC<CompanyDialogProps> = ({
  id,
  open,
  onCancelClick,
}) => {
  const dispatch = useAppDispatch();
  const loadingCompany = useSelector(LoadingCompanyDataSelector);
  const config = useSelector(ConfigSelector);
  const companyData = useSelector(CompanyDataSelector);
  // Company state
  const [companyId, setCompanyId] = useState(id);
  const [companyName, setCompanyName] = useState('');
  const [companyCode, setCompanyCode] = useState('');
  const [companyUsers, setCompanyUsers] = useState<User[]>([]);

  const [isGlobalAdminCompany, setIsGlobalAdminCompany] = useState(false);
  // Alert and ConfirmationModal
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');

  useEffect(() => {
    if (companyId) {
      dispatch(companyThunks.fetchCompanyData(companyId));
    }
  }, []);

  useEffect(() => {
    if (companyData.selectedCompany) {
      setCompanyId(companyData.selectedCompany.id);
      setCompanyName(companyData.selectedCompany.name);
      setCompanyCode(companyData.selectedCompany.code);
      setCompanyUsers(companyData.companyUsers);
    }
  }, [companyData]);

  useEffect(() => {
    if (config && companyData.selectedCompany) {
      setIsGlobalAdminCompany(companyData.selectedCompany.name === config.globalAdminCompany);
    }
  }, [config, companyData]);

  const saveCompany = async (companyData: NewCompanyDto | Company) => {
    let company: Company | null;
    if (!companyId) {
      company = await dispatch(companyThunks.createCompany(companyData as NewCompanyDto));
    } else {
      company = await dispatch(companyThunks.updateCompany(companyData as Company));
    }

    if (company) {
      setAlertMessage(`Company ${company.name} was saved!`);
      setAlertOpen(true);
    }
  };

  const deleteCompany = async (companyId: string) => {
    setConfirmationModalMessage('');
    setConfirmationModalOpen(false);
    const company = await dispatch(companyThunks.deleteCompany(companyId));

    if (company) {
      onCancelClick();
    }
  };

  const getConfirmationModalMessage = (companyName: string) => `You want to delete <b>${companyName}</b> company.\nThis action can't be undone.`;

  return (
    <Box>
      <Dialog
        open={open}
        fullWidth
        className={dialogStyles.dialog}
      >
        <CustomDialogTitle
          title='Company'
          className={dialogStyles['dialog-title']}
          onCancelClick={() => {
            dispatch(companyActions.clearState());
            onCancelClick();
          }}
        />
        <Formik
          initialValues={{}}
          onSubmit={() => saveCompany({
            id: companyId,
            name: companyName,
            code: companyCode,
          })}
        >
          {(props: FormikProps<{}>) => (
            <Form>
              {loadingCompany || props.isSubmitting ? (<Loading />) : (
                <>
                  <DialogContent
                    className={dialogStyles['dialog-content']}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      gap='15px'
                    >
                      <CustomInput
                        id='name'
                        fieldName='name'
                        value={companyName}
                        fieldLabel='Company name'
                        required
                        disabled={isGlobalAdminCompany}
                        onChange={(event: any) => setCompanyName(event.target.value)}
                      />
                      <CustomInput
                        id='code'
                        fieldName='code'
                        value={companyCode}
                        fieldLabel='Company code'
                        required
                        disabled={isGlobalAdminCompany}
                        onChange={(event: any) => setCompanyCode(event.target.value)}
                      />
                    </Box>
                    <List>
                      <ListSubheader>Company users:</ListSubheader>
                      <Box
                        maxHeight='300px'
                        overflow='auto'
                      >
                        {companyUsers.length > 0 ? (
                          <>
                            {companyUsers.map((user: User) => (
                              <ListItem key={user.id}>
                                {user.username}
                              </ListItem>
                            ))}
                          </>
                        ) : (
                          <ListItem>
                            <Card
                              variant='outlined'
                              sx={{
                                width: '100%',
                              }}
                            >
                              <ListItemText>
                                <Typography
                                  align='center'
                                  fontSize='12px'
                                >
                                  Empty
                                </Typography>
                              </ListItemText>
                            </Card>
                          </ListItem>
                        )}
                      </Box>
                    </List>
                  </DialogContent>
                  <Box
                    margin='5px'
                  >
                    <CustomDialogActions
                      onCloseClick={() => {
                        dispatch(companyActions.clearState());
                        onCancelClick();
                      }}
                      onDeleteClick={() => {
                        setConfirmationModalMessage(getConfirmationModalMessage(companyName));
                        setConfirmationModalOpen(true);
                      }}
                      onConfirmationCancel={() => {
                        setConfirmationModalMessage('');
                        setConfirmationModalOpen(false);
                      }}
                      onConfirm={() => deleteCompany(companyId as string)}
                      renderDeleteButton={companyId !== undefined}
                      confirmationModalOpen={confirmationModalOpen}
                      confirmationModalMessage={confirmationModalMessage}
                    />
                  </Box>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Dialog>
      <CustomAlert
        open={alertOpen}
        verticalPosition='bottom'
        horizontalPosition='center'
        durationMs={2000}
        message={alertMessage}
        onClose={() => {
          setAlertMessage('');
          setAlertOpen(false);
        }}
      />
    </Box>
  );
};

export default CompanyDialog;
