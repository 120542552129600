import {
  Action,
  AnyAction,
  CombinedState,
  configureStore,
  Dispatch,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import rootReducer from './slices/root.reducer';

export const store = configureStore({ reducer: rootReducer });

export const makeStore = () => configureStore({ reducer: rootReducer });

type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore['dispatch'];
export type TStore = ReturnType<typeof store.getState>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;
export type CustomDispatchType = ThunkDispatch<CombinedState<AppState>, unknown, AnyAction> & Dispatch<AnyAction>;
