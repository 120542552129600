import { Api } from 'constants/urls';
import { Role } from 'interfaces';
import { restService } from './rest.service';

class RolesService {
  async getRolesList(): Promise<Role[]> {
    return restService.doGet<Role[]>(Api.ROLE_LIST);
  }
}

export const rolesService = new RolesService();
