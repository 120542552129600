import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState, ConfigState } from 'interfaces';
import { configService } from 'services/config.service';
import { AppThunk, CustomDispatchType } from 'store';
import { errorActions } from './error.slice';

export type ConfigData = Omit<ConfigState, 'loading'>;

export const configState: ConfigState = {
  loading: false,
  globalAdminCompany: null,
  globalAdminRole: null,
  docsLink: null,
  enableLogs: false,
};

const configSlice = createSlice({
  name: 'config',
  initialState: configState,
  reducers: {
    setConfig: (state, action: PayloadAction<ConfigData>) => {
      state.globalAdminCompany = action.payload.globalAdminCompany;
      state.globalAdminRole = action.payload.globalAdminRole;
      state.docsLink = action.payload.docsLink;
      state.enableLogs = action.payload.enableLogs;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    clearConfig: (state) => {
      state.globalAdminCompany = null;
      state.globalAdminRole = null;
      state.docsLink = null;
      state.enableLogs = false;
    },
  },
});

const fetchConfig = (): AppThunk => async (dispatch: CustomDispatchType) => {
  dispatch(configActions.setLoading(true));

  try {
    const config = await configService.getConfig();

    dispatch(configActions.setConfig(config));
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(configActions.setLoading(false));
  }
};

export const configThunks = {
  fetchConfig,
};

export const ConfigSelector = (state: AppState) => state.config;

export const configActions = configSlice.actions;

export default configSlice.reducer;
