import React from 'react';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';

interface ItemData {
  label: string;
  value: any;
}

interface CustomSelectProps {
  selectName: string;
  value?: string;
  selectMap: ItemData[];
  selectLabel: string;
  onChange: (e:any) => void;
  fullWidth?: boolean;
  disabled?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  selectName, value, selectLabel, selectMap, onChange, fullWidth, disabled,
}) => (
  <FormControl fullWidth={fullWidth}>
    <InputLabel>{selectLabel}</InputLabel>
    <Select
      name={selectName}
      value={value || ''}
      label={selectLabel}
      onChange={onChange}
      fullWidth
      disabled={disabled}
    >
      {selectMap.map((item) => (
        <MenuItem
          key={item.label}
          value={item.value}
        >
          {item.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default CustomSelect;
