import * as React from 'react';
import {
  Dialog, DialogContent, Tab, Tabs,
} from '@mui/material';
import TabPanel from 'components/Tab/TabPanel';
import { MockCallLog } from 'interfaces/log.interfaces';
import LogDetails from './MockCallsLogDetails';
import CustomDialogTitle from '../../CustomDialogTitle/CustomDialogTitle';

interface MockCallsLogDialogProps {
  onCancelClick: () => void;
  logDetails: MockCallLog;
}

const MockCallsLogDialog: React.FC<MockCallsLogDialogProps> = ({ onCancelClick, logDetails }) => {
  const [verticalTabIndex, setVerticalTabIndex] = React.useState(0);

  return (
    <Dialog
      fullScreen
      open
    >
      <CustomDialogTitle
        title='Log Details'
        onCancelClick={onCancelClick}
      />
      <DialogContent
        dividers
        sx={{ display: 'flex' }}
      >
        <Tabs
          orientation='vertical'
          sx={{ minWidth: '10%' }}
          value={verticalTabIndex}
          onChange={(event, value) => setVerticalTabIndex(value)}
        >
          <Tab label='REQUEST' />
          <Tab label='RESPONSE' />
        </Tabs>

        <TabPanel
          value={verticalTabIndex}
          index={0}
        >
          <LogDetails details={logDetails.requestDetails} />
        </TabPanel>

        <TabPanel
          value={verticalTabIndex}
          index={1}
        >
          <LogDetails details={logDetails.responseDetails} />
        </TabPanel>

      </DialogContent>
    </Dialog>
  );
};

export default MockCallsLogDialog;
