import { Api } from 'constants/urls';
import { ErrorLog, MockCallLog } from 'interfaces/log.interfaces';
import { restService } from './rest.service';

class LogsService {
  async getMockCallsLogs(): Promise<MockCallLog[]> {
    return restService.doGet<MockCallLog[]>(Api.MOCK_CALLS_LOGS_LIST);
  }

  async getErrorLogs(): Promise<ErrorLog[]> {
    return restService.doGet<ErrorLog[]>(Api.ERROR_LOGS_LIST);
  }

  async clearMockCallsList(): Promise<void> {
    return restService.doDelete(Api.MOCK_CALLS_LOGS);
  }

  async clearErrorList(): Promise<void> {
    return restService.doDelete(Api.ERROR_LOGS);
  }
}

export const logsService = new LogsService();
