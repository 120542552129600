import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState, Role, RolesState } from 'interfaces';
import { rolesService } from 'services/roles.service';
import { AppThunk, CustomDispatchType } from 'store';
import { errorActions } from './error.slice';

export const rolesState: RolesState = {
  list: [],
  loading: false,
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState: rolesState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.list = action.payload;
    },
    clearRoles: (state) => {
      state.list = [];
    },
  },
});

const getRoles = (): AppThunk => async (dispatch: CustomDispatchType) => {
  dispatch(rolesActions.setLoading(true));
  dispatch(rolesActions.clearRoles());

  try {
    const response = await rolesService.getRolesList();

    dispatch(rolesActions.setRoles(response));
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(rolesActions.setLoading(false));
  }
};

export const rolesThunks = {
  getRoles,
};

export const RolesListSelector = (state: AppState) => state.roles.list;
export const LoadingRolesSelector = (state: AppState) => state.roles.loading;

export const rolesActions = rolesSlice.actions;

export default rolesSlice.reducer;
