import { Api } from 'constants/urls';
import { MockResponse, MockResponseData, NewMockResponseDto } from 'interfaces/mock-response.interfaces';
import { restService } from './rest.service';

class MockResponseService {
  async getByTemplateId(templateId: string): Promise<MockResponse[]> {
    return restService.doGet<MockResponse[]>(`${Api.MOCK_RESPONSES_BY_TEMPLATE}/${templateId}`);
  }

  async getById(id: string): Promise<MockResponse> {
    return restService.doGet<MockResponse>(`${Api.MOCK_RESPONSE}/${id}`);
  }

  async create(mockResponseData: NewMockResponseDto): Promise<MockResponse> {
    return restService.doPost<MockResponse>(`${Api.MOCK_RESPONSE}`, mockResponseData);
  }

  async update(mockResponseData: MockResponseData): Promise<MockResponse> {
    return restService.doPut<MockResponse>(`${Api.MOCK_RESPONSE}`, mockResponseData);
  }

  async deleteResponse(id: string): Promise<MockResponse> {
    return restService.doDelete<MockResponse>(`${Api.MOCK_RESPONSE}/${id}`);
  }
}

export const mockResponseService = new MockResponseService();
