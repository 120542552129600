import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Form, Formik, FormikProps } from 'formik';
import { NewTemplateDto, Template } from 'interfaces';
import { TemplateTypes } from 'enums/template-types.enum';
import { RequestMethods } from 'enums/request-methods.enum';
import Split from 'react-split';
import { useSelector } from 'react-redux';
import {
  LoadingTemplateSelector,
  templateThunks,
  SelectedTemplateSelector,
  templateActions,
} from 'store/slices/template.slice';
import { useAppDispatch } from 'store/hooks';
import { mockResponseActions, mockResponseThunks } from 'store/slices/mock-response.slice';

import CustomAlert from 'components/CustomAlert/CustomAlert';
import Loading from 'components/Loading/Loading';
import CustomDialogTitle from '../CustomDialogTitle/CustomDialogTitle';
import SelectedResponse from './SelectedResponse/SelectedResponse';
import TemplateInfo from './TemplateInfo/TemplateInfo';
import TemplateResponses from './TemplateResponses/TemplateResponses';

import styles from './TemplateDialog.module.scss';
import dialogStyles from '../CustomDialog.module.scss';
import CustomDialogActions from '../CustomDialogActions/CustomDialogActions';

interface TemplateDialogProps {
  onCancelClick: () => void;
  open: boolean;
  id: string | undefined;
}

const TemplateDialog: React.FC<TemplateDialogProps> = ({
  onCancelClick, open, id,
}) => {
  const dispatch = useAppDispatch();
  const loadingTemplate = useSelector(LoadingTemplateSelector);
  const selectedTemplate = useSelector(SelectedTemplateSelector);
  // Template state
  const [templateId, setTemplateId] = useState<string | undefined>(id);
  const [templateName, setTemplateName] = useState('');
  const [templateUrl, setTemplateUrl] = useState('');
  const [templateType, setTemplateType] = useState(TemplateTypes.REST);
  const [requestMethod, setRequestMethod] = useState<undefined | RequestMethods>(RequestMethods.GET);
  const [soapAction, setSoapAction] = useState<undefined | string>('');
  // Selected response state
  const [selectedResponseId, setSelectedResponseId] = useState<null | string>(null);
  const [selectedResponseBody, setSelectedResponseBody] = useState<null | string>(null);
  const [selectedResponseStatusCode, setSelectedResponseStatusCode] = useState<null | number>(null);
  const [selectedResponseActive, setSelectedResponseActive] = useState<null | boolean>(null);
  const [selectedResponseName, setSelectedResponseName] = useState<null | string>(null);
  // Alert and ConfirmationModal
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');

  useEffect(() => {
    if (selectedTemplate) {
      setTemplateId(selectedTemplate.id);
      setTemplateName(selectedTemplate.name);
      setTemplateUrl(selectedTemplate.url);
      setTemplateType(selectedTemplate.type);
      setRequestMethod(selectedTemplate.requestMethod);
      setSoapAction(selectedTemplate.soapAction);
    }

    return () => {
      dispatch(mockResponseThunks.clearAllResponses());
    };
  }, [selectedTemplate]);

  const saveTemplate = async (templateData: NewTemplateDto | Template) => {
    let template: Template | null;
    if (!templateId) {
      template = await dispatch(templateThunks.createTemplate(templateData as NewTemplateDto));
    } else {
      template = await dispatch(templateThunks.updateTemplate(templateData as Template));
      if (selectedResponseId) {
        await dispatch(mockResponseThunks.updateSelectedResponse({
          id: selectedResponseId,
          body: selectedResponseBody as string,
          statusCode: selectedResponseStatusCode as number,
          active: selectedResponseActive as boolean,
          mockResponseName: selectedResponseName as string,
        }));
      }
    }

    // If call to save template fails then thunk returns null, so in this case we shouldn't show alert
    if (template) {
      setAlertMessage(`Template ${template.name} was saved!`);
      setAlertOpen(true);
    }
  };

  const deleteTemplate = async (templateId: string) => {
    setConfirmationModalMessage('');
    setConfirmationModalOpen(false);
    const template = await dispatch(templateThunks.deleteTemplate(templateId));
    // If call to delete template fails then thunk returns null, so in this case we shouldn't show alert
    if (template) {
      dispatch(mockResponseActions.clearSelectedResponse());
      onCancelClick();
    }
  };

  useEffect(() => {
    if (templateId) {
      dispatch(templateThunks.fetchTemplate(templateId));
    }
  }, []);

  const gutterStyles = () => ({
    backgroundColor: '#eee',
    backgroundRepeat: 'no-repeat',
    width: '10px',
    backgroundPosition: '50%',
    cursor: 'col-resize',
    backgroundImage: 'url(\'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==\')',
  });

  const getCardMessage = (): string => {
    if (!templateId) {
      return 'Save template to add new responses';
    }
    return 'Select some response';
  };

  const getConfirmationModalMessage = (templateName: string) => `You want to delete <b>${templateName}</b> template.\nThis action can't be undone.`;

  return (
    <Box>
      <Dialog
        open={open}
        maxWidth='xl'
        fullWidth
        className={dialogStyles.dialog}
      >
        <CustomDialogTitle
          title='Template'
          className={dialogStyles['dialog-title']}
          onCancelClick={() => {
            dispatch(templateActions.clearSelectedTemplate());
            onCancelClick();
          }}
        />
        <Formik
          initialValues={{}}
          onSubmit={() => saveTemplate({
            id: templateId,
            name: templateName,
            url: templateUrl,
            type: templateType,
            requestMethod,
            soapAction,
          })}
        >
          {(props: FormikProps<{}>) => (
            <Form style={{ height: '80vh' }}>
              {loadingTemplate || props.isSubmitting ? (<Loading />) : (
                <>
                  <DialogContent
                    sx={{
                      overflowY: 'hidden',
                    }}
                    className={dialogStyles['dialog-content']}
                  >
                    <Split
                      sizes={[40, 60]}
                      className={styles.split}
                      direction='horizontal'
                      gutterStyle={gutterStyles}
                      style={{ height: '100%' }}
                    >
                      <Box className={styles.splitColumn}>
                        <Box
                          height='45%'
                          overflow='auto'
                          paddingTop='5px'
                        >
                          <TemplateInfo
                            templateName={templateName}
                            templateUrl={templateUrl}
                            templateType={templateType}
                            templateRequestMethod={requestMethod}
                            templateSoapAction={soapAction}
                            setTemplateName={setTemplateName}
                            setTemplateUrl={setTemplateUrl}
                            setTemplateType={setTemplateType}
                            setTemplateRequestMethod={setRequestMethod}
                            setSoapAction={setSoapAction}
                          />
                        </Box>
                        {templateId && (
                          <Box height='55%'>
                            <TemplateResponses
                              templateId={templateId}
                              selectedResponseId={selectedResponseId}
                              setSelectedResponseId={setSelectedResponseId}
                              setSelectedResponseName={setSelectedResponseName}
                              setSelectedResponseBody={setSelectedResponseBody}
                              setSelectedResponseActive={setSelectedResponseActive}
                              setSelectedResponseStatusCode={setSelectedResponseStatusCode}
                            />
                          </Box>
                        )}
                      </Box>
                      <Box className={styles.splitColumn}>
                        {templateId && selectedResponseId ? (
                          <SelectedResponse
                            responseId={selectedResponseId}
                            responseName={selectedResponseName}
                            statusCode={selectedResponseStatusCode}
                            responseBody={selectedResponseBody}
                            active={selectedResponseActive}
                            responseBodyType={templateType === TemplateTypes.REST ? 'js' : 'xml'}
                            setActive={setSelectedResponseActive}
                            setResponseBody={setSelectedResponseBody}
                            setResponseName={setSelectedResponseName}
                            setStatusCode={setSelectedResponseStatusCode}
                          />

                        ) : (
                          <Box
                            width='100%'
                            height='100%'
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                          >
                            <Card
                              variant='outlined'
                              sx={{
                                width: '500px',
                                height: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Typography align='center'>{getCardMessage()}</Typography>
                            </Card>
                          </Box>
                        )}
                      </Box>
                    </Split>
                  </DialogContent>
                  <CustomDialogActions
                    onCloseClick={() => {
                      dispatch(templateActions.clearSelectedTemplate());
                      onCancelClick();
                    }}
                    onDeleteClick={() => {
                      setConfirmationModalMessage(getConfirmationModalMessage(templateName));
                      setConfirmationModalOpen(true);
                    }}
                    onConfirmationCancel={() => {
                      setConfirmationModalMessage('');
                      setConfirmationModalOpen(false);
                    }}
                    onConfirm={() => deleteTemplate(templateId as string)}
                    renderDeleteButton={templateId !== undefined}
                    confirmationModalMessage={confirmationModalMessage}
                    confirmationModalOpen={confirmationModalOpen}
                  />
                </>
              )}
            </Form>
          )}
        </Formik>
      </Dialog>
      <CustomAlert
        open={alertOpen}
        verticalPosition='bottom'
        horizontalPosition='center'
        durationMs={2000}
        message={alertMessage}
        onClose={() => {
          setAlertMessage('');
          setAlertOpen(false);
        }}
      />
    </Box>
  );
};

export default TemplateDialog;
