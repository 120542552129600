import { StatusCodes } from 'http-status-codes';

const customStatusCodes = [
  443,
];

const statusCodes = Object.values(StatusCodes)
  .filter((codeValue) => typeof (codeValue) === 'number')
  .sort();

export const CustomStatusCodes = Array.from(new Set([...customStatusCodes, ...statusCodes])).sort();
