import React, { useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Form, Formik, FormikProps } from 'formik';
import DialogContent from '@mui/material/DialogContent';
import { NewUserDto, User } from 'interfaces';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import { CompaniesListSelector, companiesThunks } from 'store/slices/companies.slice';
import { RolesListSelector, rolesThunks } from 'store/slices/roles.slice';
import {
  SelectedUserSelector,
  userActions,
  userThunks,
} from 'store/slices/user.slice';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import CustomAlert from 'components/CustomAlert/CustomAlert';
import Loading from 'components/Loading/Loading';
import { ConfigSelector } from 'store/slices/config.slice';
import CustomDialogTitle from '../CustomDialogTitle/CustomDialogTitle';
import CustomInput from '../../CustomInput/CustomInput';
import CustomSelect from '../../CustomSelect/CustomSelect';
import CustomDialogActions from '../CustomDialogActions/CustomDialogActions';

import dialogStyles from '../CustomDialog.module.scss';

interface UserDialogProps {
  onCancelClick: () => void;
  open: boolean;
  id: string | undefined;
}

const UserDialog: React.FC<UserDialogProps> = ({
  onCancelClick,
  open,
  id,
}) => {
  const dispatch = useAppDispatch();
  const selectedUser = useSelector(SelectedUserSelector);
  const roleList = useSelector(RolesListSelector);
  const companyList = useSelector(CompaniesListSelector);
  const config = useSelector(ConfigSelector);

  const [allDataLoaded, setAllDataLoaded] = useState(false);
  // User state
  const [userId, setUserId] = useState<undefined | string>(id);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [roleId, setRoleId] = useState<undefined | string>(undefined);
  const [isGlobalAdmin, setIsGlobalAdmin] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<undefined | string>(undefined);
  // Alert and ConfirmationModal
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      let userPromise = null;
      if (userId) {
        userPromise = dispatch(userThunks.fetchUser(userId));
      }

      await Promise.all([
        userPromise,
        dispatch(rolesThunks.getRoles()),
        dispatch(companiesThunks.getCompanies()),
      ]);

      setAllDataLoaded(true);
    };

    loadData();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setUserId(selectedUser.id);
      setUsername(selectedUser.username);
      setPassword(selectedUser.password);
      setRoleId(selectedUser.roleId);
      setCompanyId(selectedUser.companyId);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser && userId) {
      setIsGlobalAdmin(selectedUser.companyName === config.globalAdminCompany);
    }
  }, [selectedUser, userId]);

  useEffect(() => {
    if (!userId && roleList.length > 0) {
      setRoleId(roleList[0].id);
    }
  }, [roleList]);

  useEffect(() => {
    if (!userId && companyList.length > 0) {
      setCompanyId(companyList[0].id);
    }
  }, [companyList]);

  const saveUser = async (userData: NewUserDto | User) => {
    let user: User | null;
    if (!userId) {
      user = await dispatch(userThunks.createUser(userData as NewUserDto));
    } else {
      user = await dispatch(userThunks.updateUser(userData as User));
    }

    if (user) {
      setAlertMessage(`User ${user.username} was saved!`);
      setAlertOpen(true);
    }
  };

  const deleteUser = async (userId: string) => {
    setConfirmationModalMessage('');
    setConfirmationModalOpen(false);
    const user = await dispatch(userThunks.deleteUser(userId));

    if (user) {
      onCancelClick();
    }
  };

  const getConfirmationModalMessage = (username: string) => `You want to delete <b>${username}</b> user.\nThis action can't be undone.`;

  return (
    <Box>
      <Dialog
        open={open}
        fullWidth
        className={dialogStyles.dialog}
      >
        <CustomDialogTitle
          title='User'
          onCancelClick={() => {
            dispatch(userActions.clearSelectedUser());
            onCancelClick();
          }}
        />
        <Formik
          initialValues={{}}
          onSubmit={() => saveUser({
            id: userId,
            username,
            password,
            roleId: roleId || '',
            companyId: companyId || '',
          })}
        >
          {(props: FormikProps<{}>) => (
            <Form style={{ maxHeight: '80vh' }}>
              {!allDataLoaded || props.isSubmitting ? (<Loading />) : (
                <>
                  <DialogContent
                    className={dialogStyles['dialog-content']}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      gap='15px'
                    >
                      <CustomInput
                        id='username'
                        fieldName='username'
                        value={username}
                        fieldLabel='Username'
                        required
                        autoCompleteOff
                        disabled={isGlobalAdmin}
                        onChange={(event: any) => setUsername(event.target.value)}
                      />
                      <CustomInput
                        id='password'
                        fieldName='password'
                        value={password}
                        fieldLabel='Password'
                        required
                        type={showPassword ? 'text' : 'password'}
                        autoCompleteOff
                        onChange={(event: any) => setPassword(event.target.value)}
                        endAdornment={(
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={() => setShowPassword((prevValue) => !prevValue)}
                              edge='end'
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )}
                      />
                      <CustomSelect
                        selectName='role'
                        value={roleId}
                        selectLabel='Role'
                        selectMap={roleList.map((role) => ({
                          label: role.name,
                          value: role.id,
                        }))}
                        fullWidth
                        disabled={isGlobalAdmin}
                        onChange={(event: any) => setRoleId(event.target.value)}
                      />
                      <CustomSelect
                        selectName='company'
                        value={companyId}
                        selectLabel='Company'
                        selectMap={companyList.map((company) => ({
                          label: company.name,
                          value: company.id,
                        }))}
                        fullWidth
                        disabled={isGlobalAdmin}
                        onChange={(event: any) => setCompanyId(event.target.value)}
                      />
                    </Box>
                  </DialogContent>
                  <Box
                    marginTop='30px'
                    marginBottom='10px'
                  >
                    <CustomDialogActions
                      onCloseClick={() => {
                        dispatch(userActions.clearSelectedUser());
                        onCancelClick();
                      }}
                      onDeleteClick={() => {
                        setConfirmationModalMessage(getConfirmationModalMessage(username));
                        setConfirmationModalOpen(true);
                      }}
                      onConfirmationCancel={() => {
                        setConfirmationModalMessage('');
                        setConfirmationModalOpen(false);
                      }}
                      onConfirm={() => deleteUser(userId as string)}
                      renderDeleteButton={userId !== undefined && !isGlobalAdmin}
                      confirmationModalOpen={confirmationModalOpen}
                      confirmationModalMessage={confirmationModalMessage}
                    />
                  </Box>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Dialog>
      <CustomAlert
        open={alertOpen}
        verticalPosition='bottom'
        horizontalPosition='center'
        durationMs={2000}
        message={alertMessage}
        onClose={() => {
          setAlertMessage('');
          setAlertOpen(false);
        }}
      />
    </Box>
  );
};

export default UserDialog;
