import React, { HTMLInputTypeAttribute } from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';

interface CustomInputProps {
  id: string;
  fieldName: string;
  value?: string;
  type?: HTMLInputTypeAttribute
  fieldLabel: string;
  fullWidth?: boolean;
  required?: boolean;
  endAdornment?: React.ReactNode,
  autoCompleteOff?: boolean;
  disabled?: boolean;
  onChange: (e: any) => void;
}

const CustomInput: React.FC<CustomInputProps> = ({
  id,
  fieldName,
  value,
  fieldLabel,
  onChange,
  type,
  fullWidth,
  required,
  autoCompleteOff = false,
  endAdornment,
  disabled,
}) => (
  <FormControl
    fullWidth
  >
    <InputLabel htmlFor={id}>{fieldLabel}</InputLabel>
    <OutlinedInput
      id={id}
      name={fieldName}
      value={value || ''}
      type={type}
      label={fieldLabel}
      onChange={onChange}
      required={required}
      fullWidth={fullWidth}
      endAdornment={endAdornment}
      disabled={disabled}
      autoComplete={autoCompleteOff ? 'new-password' : 'on'}
    />
  </FormControl>

);

export default CustomInput;
