import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState, LogsState } from 'interfaces';
import { ErrorLog, MockCallLog } from 'interfaces/log.interfaces';
import { logsService } from 'services/logs.service';
import { AppThunk, CustomDispatchType } from 'store';
import * as errorActions from 'store/slices/error.slice';

export const logsState: LogsState = {
  mockCallsLogs: [],
  loading: false,
  errorLogs: [],
};

const logsSlice = createSlice({
  name: 'logs',
  initialState: logsState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setMockCallsLogs: (state, action: PayloadAction<MockCallLog[]>) => {
      state.mockCallsLogs = action.payload;
    },
    clearMockCallsLogs: (state) => {
      state.mockCallsLogs = [];
    },
    setErrorLogs: (state, action: PayloadAction<ErrorLog[]>) => {
      state.errorLogs = action.payload;
    },
    clearErrorLogs: (state) => {
      state.errorLogs = [];
    },
  },
});

const getMockCallsLogs = (): AppThunk => async (dispatch: CustomDispatchType) => {
  dispatch(logsListActions.setLoading(true));
  dispatch(logsListActions.clearMockCallsLogs());

  try {
    const response = await logsService.getMockCallsLogs();

    dispatch(logsListActions.setMockCallsLogs(response));
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(logsListActions.setLoading(false));
  }
};

const getErrorLogs = (): AppThunk => async (dispatch: CustomDispatchType) => {
  dispatch(logsListActions.setLoading(true));
  dispatch(logsListActions.clearErrorLogs());

  try {
    const response = await logsService.getErrorLogs();

    dispatch(logsListActions.setErrorLogs(response));
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(logsListActions.setLoading(false));
  }
};

const clearMockCallsLogs = (): AppThunk => async (dispatch: CustomDispatchType) => {
  dispatch(logsListActions.setLoading(true));

  try {
    await logsService.clearMockCallsList();

    dispatch(logsListActions.clearMockCallsLogs());
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(logsListActions.setLoading(false));
  }
};

const clearErrorLogs = (): AppThunk => async (dispatch: CustomDispatchType) => {
  dispatch(logsListActions.setLoading(true));

  try {
    await logsService.clearErrorList();

    dispatch(logsListActions.clearErrorLogs());
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(logsListActions.setLoading(false));
  }
};

export const logThunks = {
  getMockCallsLogs,
  getErrorLogs,
  clearMockCallsLogs,
  clearErrorLogs,
};

export const MockCallsLogsListSelector = (state: AppState) => state.logs.mockCallsLogs;
export const ErrorLogsListSelector = (state: AppState) => state.logs.errorLogs;
export const LogsLoadingSelector = (state: AppState) => state.logs.loading;

export const logsListActions = logsSlice.actions;

export default logsSlice.reducer;
