import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from '@mui/material';
import CustomAlert from 'components/CustomAlert/CustomAlert';
import { Stack } from '@mui/system';
import DownloadIcon from '@mui/icons-material/Download';
import { exportImportService } from 'services/export-import.service';
import { useAppDispatch } from 'store/hooks';
import { errorActions } from 'store/slices/error.slice';
import { LoadingButton } from '@mui/lab';
import CustomDialogTitle from '../CustomDialogTitle/CustomDialogTitle';

import dialogStyles from '../CustomDialog.module.scss';

interface UploadFileDialogProps {
  open: boolean;
  onClose: (importFinished: boolean) => void;
}

const UploadFileDialog: React.FC<UploadFileDialogProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const [selectedFilePath, setSelectedFilePath] = useState('');
  const [selectedFile, setSelectedFile] = useState<null | File>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [importing, setImporting] = useState(false);

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilePath(event.target.value);
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleImport = async () => {
    if (!selectedFile) {
      setOpenAlert(true);

      return;
    }

    const data = new FormData();
    data.append('file', selectedFile);
    setImporting(true);

    try {
      await exportImportService.import(data);

      onClose(true);
    } catch (err: any) {
      dispatch(errorActions.setError({
        message: err.response.data.message,
        errorStatus: err.response.status,
      }));
    } finally {
      setImporting(false);
    }
  };

  return (
    <Box>
      <Dialog
        open={open}
        fullWidth
        className={dialogStyles.dialog}
      >
        <CustomDialogTitle
          title='Upload file to Import'
          className={dialogStyles['dialog-title']}
          onCancelClick={() => onClose(false)}
        />
        <DialogContent className={dialogStyles['dialog-content']}>
          <Paper
            variant='outlined'
          >
            <Box
              padding='10px'
            >
              <Typography
                variant='h6'
              >
                NOTE:
              </Typography>
              <Typography
                variant='body1'
              >
                This action will override whole templates, so all your current templates and responses will be lost
              </Typography>
            </Box>
          </Paper>
          <Box
            marginTop='15px'
          >
            <InputLabel
              htmlFor='select-file-input'
            >
              Selected file
            </InputLabel>
            <OutlinedInput
              id='select-file-input'
              fullWidth
              value={selectedFilePath}
              endAdornment={(
                <Button
                  component='label'
                  variant='contained'
                  startIcon={<DownloadIcon />}
                  sx={{
                    width: '200px',
                  }}
                >
                  Select file
                  <input
                    type='file'
                    hidden
                    accept='application/json'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileInputChange(event)}
                  />
                </Button>
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions
          className={dialogStyles['dialog-footer']}
        >
          <Stack
            direction='row'
            spacing='15px'
            marginBottom='10px'
          >
            <LoadingButton
              variant='contained'
              loading={importing}
              onClick={handleImport}
            >
              Import
            </LoadingButton>
            <Button
              variant='outlined'
              onClick={() => onClose(false)}
            >
              Close
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <CustomAlert
        open={openAlert}
        message='Please, select some file first'
        durationMs={2000}
        severity='warning'
        verticalPosition='bottom'
        horizontalPosition='center'
        onClose={() => setOpenAlert(false)}
      />
    </Box>
  );
};

export default UploadFileDialog;
