import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  Navigate,
} from 'react-router-dom';
import { Routes, useNavigate } from 'react-router';
import {
  authThunks,
  IsAuthenticatedSelector, IsLoading,
} from 'store/slices/auth.slice';
import { authService } from 'services/auth.service';
import LoginPage from 'pages/Login/Login.page';
import UsersPage from 'pages/Users/Users.page';
import Layout from 'components/Layout/Layout';
import { useAppDispatch } from 'store/hooks';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import { ErrorSelector } from 'store/slices/error.slice';
import TemplatesPage from 'pages/Templates/Templates.page';
import CompaniesPage from 'pages/Companies/Companies.page';
import { configThunks } from 'store/slices/config.slice';
import RoleGuard from 'guards/RoleGuard';
import { UserRoles } from 'enums/user-roles.enum';
import Loading from 'components/Loading/Loading';
import AuthGuard from 'guards/AuthGuard';
import LogsPage from 'pages/Logs/Logs.page';
import setupAxiosInterceptors from './hooks/axios-interceptor.hook';

const App = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(IsAuthenticatedSelector);
  const isLoadingAuthData = useSelector(IsLoading);
  const errorData = useSelector(ErrorSelector);
  setupAxiosInterceptors();

  useEffect(() => {
    if (authService.getJwt()) {
      dispatch(authThunks.autoLogin());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticated && !isLoadingAuthData) {
      navigate('/login');
    }
  }, [isAuthenticated, isLoadingAuthData]);

  useEffect(() => {
    dispatch(configThunks.fetchConfig());
    dispatch(authThunks.autoLogin());
  }, []);

  return (
    <>
      <Routes>
        <Route
          path='/templates'
          element={(
            <AuthGuard>
              <Layout>
                {isLoadingAuthData ? <Loading /> : (
                  <RoleGuard
                    requiredRole={UserRoles.USER}
                    redirectLink='/users'
                  >
                    <TemplatesPage />
                  </RoleGuard>
                )}
              </Layout>
            </AuthGuard>
            )}
        />
        <Route
          path='/logs'
          element={(
            <AuthGuard>
              <Layout>
                {isLoadingAuthData ? <Loading /> : (
                  <LogsPage />
                )}
              </Layout>
            </AuthGuard>
            )}
        />
        <Route
          path='/users'
          element={(
            <AuthGuard>
              <Layout>
                {isLoadingAuthData ? <Loading /> : (
                  <RoleGuard
                    requiredRole={UserRoles.GLOBAL_ADMIN}
                    redirectLink='/templates'
                  >
                    <UsersPage />
                  </RoleGuard>
                )}
              </Layout>
            </AuthGuard>
          )}
        />
        <Route
          path='/companies'
          element={(
            <AuthGuard>
              <Layout>
                {isLoadingAuthData ? <Loading /> : (
                  <RoleGuard
                    requiredRole={UserRoles.GLOBAL_ADMIN}
                    redirectLink='/templates'
                  >
                    <CompaniesPage />
                  </RoleGuard>
                )}
              </Layout>
            </AuthGuard>
          )}
        />
        <Route
          path='/login'
          element={
            isLoadingAuthData ? <Loading />
              : !isAuthenticated ? <LoginPage />
                : <Navigate to='/templates' />
           }
        />
        <Route
          path='*'
          element={<Navigate to='/templates' />}
        />
      </Routes>
      {errorData.errorOccured && <ErrorComponent />}
    </>
  );
};
export default App;
