import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppState,
  NewTemplateDto,
  Template,
  TemplateData,
  TemplateState,
} from 'interfaces';
import { templatesService } from 'services/templates.service';
import { AppThunk, CustomDispatchType } from 'store';
import { errorActions } from './error.slice';

export const templateState: TemplateState = {
  selectedTemplate: null,
  loading: false,
};

const templateSlice = createSlice({
  name: 'template',
  initialState: templateState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSelectedTemplate: (state, action: PayloadAction<Template>) => {
      state.selectedTemplate = action.payload;
    },
    clearSelectedTemplate: (state) => {
      state.selectedTemplate = null;
    },
  },
});

const fetchTemplate = (templateId: string): AppThunk<Promise<Template | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(templateActions.setLoading(true));

  try {
    const template = await templatesService.getById(templateId);

    dispatch(templateActions.setSelectedTemplate(template));
    return template;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(templateActions.setLoading(false));
  }

  return null;
};

const createTemplate = (newTemplate: NewTemplateDto): AppThunk<Promise<Template | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(templateActions.setLoading(true));
  dispatch(templateActions.clearSelectedTemplate());

  try {
    const template = await templatesService.create(newTemplate);
    dispatch(templateActions.setSelectedTemplate(template));

    return template;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(templateActions.setLoading(false));
  }

  return null;
};

const updateTemplate = (templateData: TemplateData): AppThunk<Promise<Template | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(templateActions.setLoading(true));
  dispatch(templateActions.clearSelectedTemplate());

  try {
    const template = await templatesService.update(templateData);
    dispatch(templateActions.setSelectedTemplate(template));

    return template;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(templateActions.setLoading(false));
  }

  return null;
};

const deleteTemplate = (templateId: string): AppThunk<Promise<Template | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(templateActions.setLoading(true));

  try {
    const deletedTemplate = await templatesService.delete(templateId);
    dispatch(templateActions.clearSelectedTemplate());

    return deletedTemplate;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(templateActions.setLoading(false));
  }

  return null;
};

export const TemplateSelector = (state: AppState) => state.template;
export const LoadingTemplateSelector = (state: AppState) => state.template.loading;
export const SelectedTemplateSelector = (state: AppState) => state.template.selectedTemplate;

export const templateActions = templateSlice.actions;

export const templateThunks = {
  fetchTemplate,
  createTemplate,
  updateTemplate,
  deleteTemplate,
};

export default templateSlice.reducer;
