import React from 'react';
import { CircularProgress } from '@mui/material';
import classes from './Loading.module.scss';

interface LoadingProps {
  size?: number;
}

const Loading: React.FC<LoadingProps> = (props: LoadingProps) => (
  <div className={classes.loadingContainer}>
    <CircularProgress size={props.size || 40} />
  </div>
);

export default Loading;
