import axios from 'axios';
import { NewTemplateDto, Template, TemplateData } from 'interfaces';
import { PaginationResponse, PaginationOptions } from 'interfaces/pagination.interface';
import { Api } from '../constants/urls';
import { restService } from './rest.service';

class TemplatesService {
  async getList(paginationOptions: PaginationOptions): Promise<{ data: Template[], meta: PaginationResponse }> {
    const response = await axios.get<{ data: Template[], meta: PaginationResponse }>(Api.TEMPLATES_LIST, { params: paginationOptions });
    return response.data;
  }

  async getById(id: string): Promise<Template> {
    const template = await axios.get<Template>(`${Api.TEMPLATE}/${id}`);

    return template.data;
  }

  async create(template: NewTemplateDto): Promise<Template> {
    const response = await axios.post<Template>(Api.TEMPLATE, template);

    return response.data;
  }

  async update(template: TemplateData): Promise<Template> {
    const response = await axios.put<Template>(Api.TEMPLATE, template);

    return response.data;
  }

  async delete(id: string): Promise<Template> {
    return restService.doDelete(`${Api.TEMPLATE}/${id}`);
  }
}

export const templatesService = new TemplatesService();
