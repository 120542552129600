import axios from 'axios';
import { UserInfo } from 'interfaces';
import { useEffect } from 'react';
import { authService } from 'services/auth.service';
import { useAppDispatch } from 'store/hooks';
import { authThunks } from 'store/slices/auth.slice';

const useAxiosInterceptors = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    axios.interceptors.request.use((req) => {
      const headers: Record<string, string> = {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
        'If-Modified-Since': '0',
      };
      const jwtToken = authService.getJwt();
      if (jwtToken) {
        const encryptedInfo = jwtToken.split('.')[1];
        const authData: UserInfo = JSON.parse(atob(encryptedInfo));

        dispatch(authThunks.setAuthData({
          username: authData.username,
          role: authData.role,
          company: authData.company,
          companyCode: authData.companyCode,
        }));

        headers.authorization = `Bearer ${jwtToken}`;
      }

      req.headers = headers;
      return req;
    });

    axios.interceptors.response.use((res) => {
      const jwtToken = res.headers['jwt-token'];
      if (jwtToken) {
        authService.setJwt(jwtToken);
        dispatch(authThunks.autoLogin());
      }
      return res;
    });
  }, [dispatch]);
};

export default useAxiosInterceptors;
