import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState, AuthState, UserInfo } from 'interfaces';
import { authService } from 'services/auth.service';
import { AppThunk, CustomDispatchType } from 'store';
import * as errorActions from 'store/slices/error.slice';

type AuthStateSlice = Omit<AuthState, 'loading' | 'isAuthenticated'>;

export const authState: AuthState = {
  loading: true,
  isAuthenticated: false,
  username: '',
  company: '',
  companyCode: '',
  role: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAuthData: (state, { payload }: PayloadAction<AuthStateSlice>) => {
      state.username = payload.username;
      state.role = payload.role;
      state.company = payload.company;
      state.companyCode = payload.companyCode;
      state.isAuthenticated = true;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    clearAuthData: (state) => {
      state.username = '';
      state.role = null;
      state.company = '';
      state.companyCode = '';
      state.isAuthenticated = false;
    },
  },
});

const login = (username: string, password: string): AppThunk<Promise<boolean>> => async (dispatch: CustomDispatchType) => {
  dispatch(authActions.setLoading(true));

  try {
    const authData = await authService.login(username, password);
    dispatch(setAuthData({
      username: authData.username,
      role: authData.role,
      jwtToken: authData.jwtToken,
      company: authData.company,
      companyCode: authData.companyCode,
    }));

    return true;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(authActions.setLoading(false));
  }

  return false;
};

const logout = (): AppThunk => async (dispatch: CustomDispatchType) => {
  dispatch(authActions.setLoading(true));

  try {
    authService.clearJwt();
    dispatch(authActions.clearAuthData());
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(authActions.setLoading(false));
  }
};

const setAuthData = (userInfo: UserInfo): AppThunk => async (dispatch: CustomDispatchType) => {
  dispatch(authActions.setLoading(true));

  try {
    dispatch(authActions.setAuthData({
      username: userInfo.username,
      role: userInfo.role,
      company: userInfo.company,
      companyCode: userInfo.companyCode,
    }));
    if (userInfo.jwtToken) {
      authService.clearJwt();
      authService.setJwt(userInfo.jwtToken);
    }
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(authActions.setLoading(false));
  }
};

const autoLogin = (): AppThunk => async (dispatch: CustomDispatchType) => {
  dispatch(authActions.setLoading(true));

  try {
    const userData = await authService.autoLogin();

    dispatch(authActions.setAuthData({
      username: userData.username,
      role: userData.role,
      company: userData.company,
      companyCode: userData.companyCode,
    }));
  } catch (err: any) {
    if (err.response.data.statusCode === 401) {
      dispatch(authActions.setAuthenticated(false));
    } else {
      dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
    }
  } finally {
    dispatch(authActions.setLoading(false));
  }
};

export const authThunks = {
  login,
  autoLogin,
  logout,
  setAuthData,
};

export const AuthSelector = (state: AppState) => state.auth;
export const IsAuthenticatedSelector = (state: AppState) => state.auth.isAuthenticated;
export const IsLoading = (state: AppState) => state.auth.loading;

export const authActions = authSlice.actions;

export default authSlice.reducer;
