import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as errorActions from 'store/slices/error.slice';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { useAppDispatch } from 'store/hooks';
import { authThunks } from 'store/slices/auth.slice';

const ErrorComponent: React.FC = () => {
  const errorData = useSelector(errorActions.ErrorSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (errorData.errorStatus === 401) {
      dispatch(authThunks.logout());
    }
  }, []);

  return (
    <Snackbar
      open={errorData.errorOccured}
      onClose={() => dispatch(errorActions.removeError())}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert
        severity='error'
        variant='filled'
      >
        {errorData.errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default ErrorComponent;
