import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppState,
  NewUserDto,
  User,
  UserState,
  UserWithCompanyName,
} from 'interfaces';
import { usersService } from 'services/users.service';
import { AppThunk, CustomDispatchType } from 'store';
import { errorActions } from './error.slice';

export const userState: UserState = {
  selectedUser: null,
  loading: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<UserWithCompanyName>) => {
      state.selectedUser = action.payload;
    },
    clearSelectedUser: (state) => {
      state.selectedUser = null;
    },
  },
});

const fetchUser = (userId: string): AppThunk<Promise<User | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(userActions.setLoading(true));

  try {
    const user = await usersService.getById(userId);
    dispatch(userActions.setSelectedUser(user));

    return user;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(userActions.setLoading(false));
  }

  return null;
};

const createUser = (newUser: NewUserDto): AppThunk<Promise<User | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(userActions.setLoading(true));

  try {
    const user = await usersService.create(newUser);
    dispatch(userActions.setSelectedUser(user));

    return user;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(userActions.setLoading(false));
  }

  return null;
};

const updateUser = (userData: User): AppThunk<Promise<User | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(userActions.setLoading(true));

  try {
    const user = await usersService.update(userData);
    dispatch(userActions.setSelectedUser(user));

    return user;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(userActions.setLoading(false));
  }

  return null;
};

const deleteUser = (userId: string): AppThunk<Promise<User | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(userActions.setLoading(true));

  try {
    const user = await usersService.deleteById(userId);
    dispatch(userActions.clearSelectedUser());

    return user;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(userActions.setLoading(false));
  }

  return null;
};

export const UserSelector = (state: AppState) => state.user;
export const LoadingUserSelector = (state: AppState) => state.user.loading;
export const SelectedUserSelector = (state: AppState) => state.user.selectedUser;

export const userThunks = {
  fetchUser,
  createUser,
  updateUser,
  deleteUser,
};

export const userActions = userSlice.actions;

export default userSlice.reducer;
