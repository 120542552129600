import { NewUserDto, User, UserWithCompanyName } from 'interfaces';
import { PaginationOptions, PaginationResponse } from 'interfaces/pagination.interface';
import { Api } from '../constants/urls';
import { restService } from './rest.service';

class UserService {
  async getUsersList(paginationOptions: PaginationOptions): Promise<{ data: User[], meta: PaginationResponse }> {
    return restService.doGet<{ data: User[], meta: PaginationResponse }>(Api.USERLIST, { params: paginationOptions });
  }

  async getById(userId: string): Promise<UserWithCompanyName> {
    return restService.doGet<UserWithCompanyName>(`${Api.USER}/${userId}`);
  }

  async getCompanyUsers(companyId: string): Promise<User[]> {
    return restService.doGet<User[]>(`${Api.USER}/by-company/${companyId}`);
  }

  async create(user: NewUserDto): Promise<UserWithCompanyName> {
    return restService.doPost<UserWithCompanyName>(Api.USER, user);
  }

  async update(user: User): Promise<UserWithCompanyName> {
    return restService.doPut<UserWithCompanyName>(Api.USER, user);
  }

  async deleteById(id: string): Promise<User> {
    return restService.doDelete<User>(`${Api.USER}/${id}`);
  }
}

export const usersService = new UserService();
