import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { authThunks } from 'store/slices/auth.slice';
import { useAppDispatch } from 'store/hooks';
import { useNavigate } from 'react-router';
import {
  IconButton,
  InputAdornment,
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Form, Formik, FormikProps } from 'formik';
import CustomInput from 'components/CustomInput/CustomInput';

const Copyright = (props: any) => (
  <Typography
    variant='body2'
    color='text.secondary'
    align='center'
    {...props}
  >
    {'Copyright © '}
    <Link
      color='inherit'
      href='https://mui.com/'
    >
      Mock Service
    </Link>
    {' '}
    {new Date().getFullYear()}
    .
  </Typography>
);

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  return (
    <Container
      component='main'
      maxWidth='xs'
    >
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '90vh',
          margin: '0',
          padding: '0',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: '#1976d2' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component='h1'
          variant='h5'
        >
          Sign in
        </Typography>
        <Formik
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={async (values: { username: string; password: string }) => {
            const success = await dispatch(authThunks.login(values.username, values.password));
            if (success) {
              navigate('/templates');
            }
          }}
        >
          {(props: FormikProps<{ username: string; password: string }>) => (
            <Form style={{ width: '33vh', marginTop: '20px' }}>
              <Box
                display='flex'
                flexDirection='column'
                gap='15px'
              >
                <CustomInput
                  id='username'
                  fieldLabel='Username'
                  fieldName='username'
                  value={props.values.username}
                  required
                  fullWidth
                  onChange={props.handleChange}
                />
                <CustomInput
                  id='password'
                  fieldLabel='Password'
                  fieldName='password'
                  required
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  value={props.values.password}
                  onChange={props.handleChange}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setShowPassword((prevValue) => !prevValue)}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </Box>
              <LoadingButton
                loading={props.isSubmitting}
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Box>
      <Copyright sx={{ mt: 8, mb: 0 }} />
    </Container>
  );
};

export default LoginPage;
