import { Box, Typography } from '@mui/material';
import CodeEditor from 'components/CodeEditor/CodeEditor';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import Loading from 'components/Loading/Loading';
import { CustomStatusCodes } from 'constants/custom-status-codes';
import { formatDateTime } from 'helpers/formaters';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import {
  mockResponseThunks,
  SelectedResponseLoadingSelector,
  SelectedResponseSelector,
} from 'store/slices/mock-response.slice';

interface SelectedResponseProps {
  responseId: string | null;
  responseName: string | null;
  responseBody: string | null;
  statusCode: number | null;
  active: boolean | null;
  responseBodyType: 'js' | 'xml';
  setResponseName: (name: string) => void;
  setResponseBody: (body: string) => void;
  setStatusCode: (statusCode: number) => void;
  setActive: (active: boolean) => void;
}

const SelectedResponse: React.FC<SelectedResponseProps> = ({
  responseId,
  responseName,
  responseBody,
  statusCode,
  active,
  responseBodyType,
  setResponseName,
  setResponseBody,
  setStatusCode,
  setActive,
}) => {
  const dispatch = useAppDispatch();
  const selectedResponse = useSelector(SelectedResponseSelector);
  const fetching = useSelector(SelectedResponseLoadingSelector);
  const [updatedAt, setUpdatedAt] = useState<string>('');
  const [lastUpdatedBy, setLastUpdatedBy] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (selectedResponse) {
      setResponseName(selectedResponse.mockResponseName);
      setResponseBody(selectedResponse.body);
      setStatusCode(selectedResponse.statusCode);
      setActive(selectedResponse.active);
      setUpdatedAt(selectedResponse.updatedAt);
      setLastUpdatedBy(selectedResponse.lastUpdatedBy?.username);
    }
  }, [selectedResponse]);

  useEffect(() => {
    if (responseId) {
      dispatch(mockResponseThunks.fetchSelectedResponse(responseId));
    }
  }, [responseId]);

  const getStatusCodesList = () => CustomStatusCodes.map((codeValue) => ({
    label: String(codeValue),
    value: String(codeValue),
  }));

  return (
    <Box
      height='100%'
      display='flex'
      flexDirection='column'
      gap='15px'
    >
      {fetching ? <Loading /> : (
        <>
          <Box
            display='flex'
            gap='15px'
          >
            <CustomSelect
              selectName='statusCode'
              value={String(statusCode)}
              selectLabel='Status code'
              selectMap={getStatusCodesList()}
              fullWidth
              onChange={(event: any) => setStatusCode(Number(event.target.value))}
            />

            <CustomSelect
              selectName='active'
              value={String(active) || 'false'}
              selectLabel='Active'
              fullWidth
              selectMap={[{ label: 'true', value: 'true' }, { label: 'false', value: 'false' }]}
              onChange={(event: any) => setActive(event.target.value === 'true')}
            />
          </Box>
          <CustomInput
            id='response-name'
            fieldName='mockResponseName'
            value={responseName || ''}
            fieldLabel='Response name'
            fullWidth
            required
            onChange={(event: any) => setResponseName(event.target.value)}
          />
          <Box
            sx={{
              height: '100%',
              overflowY: 'auto',
              border: '1px solid #ced4da',
              padding: '4px',
            }}
          >
            <Box
              height='100%'
            >
              <CodeEditor
                editorName='body'
                value={responseBody || ''}
                language={responseBodyType}
                onValueChange={(code: string) => setResponseBody(code)}
              />
            </Box>
          </Box>
          {!updatedAt || !lastUpdatedBy ? null : (
            <Box
              height='5%'
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography fontSize='12px'>
                Last updated at:
                {` ${formatDateTime(updatedAt)}`}
              </Typography>
              <Typography fontSize='12px'>
                Last updated by:
                {` ${lastUpdatedBy}`}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default SelectedResponse;
