import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AppState, MockResponseState } from 'interfaces';
import { MockResponse, MockResponseData, NewMockResponseDto } from 'interfaces/mock-response.interfaces';
import { mockResponseService } from 'services/mock-response.service';
import { AppThunk, CustomDispatchType } from 'store';
import { errorActions } from './error.slice';

export const mockResponseState: MockResponseState = {
  selectedTemplateResponses: [],
  loadingSelectedTemplateResponses: false,
  selectedResponse: null,
  loadingSelectedResponse: false,
};

const mockResponseSlice = createSlice({
  name: 'mockResponse',
  initialState: mockResponseState,
  reducers: {
    setLoadingSelectedTemplateResponses: (state, action: PayloadAction<boolean>) => {
      state.loadingSelectedTemplateResponses = action.payload;
    },
    setSelectedTemplateResponses: (state, action: PayloadAction<MockResponse[]>) => {
      state.selectedTemplateResponses = action.payload;
    },
    setLoadingSelectedResponse: (state, action: PayloadAction<boolean>) => {
      state.loadingSelectedResponse = action.payload;
    },
    setSelectedResponse: (state, action: PayloadAction<MockResponse>) => {
      state.selectedResponse = action.payload;
    },
    clearSelectedTemplateResponses: (state) => {
      state.selectedTemplateResponses = [];
    },
    clearSelectedResponse: (state) => {
      state.selectedResponse = null;
    },
  },
});

const fetchTemplateResponses = (templateId: string): AppThunk<Promise<MockResponse[] | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(mockResponseActions.setLoadingSelectedTemplateResponses(true));

  try {
    const templateResponses = await mockResponseService.getByTemplateId(templateId);

    dispatch(mockResponseActions.setSelectedTemplateResponses(templateResponses));
    return templateResponses;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(mockResponseActions.setLoadingSelectedTemplateResponses(false));
  }

  return null;
};

const createTemplateResponse = (newResponse: NewMockResponseDto): AppThunk<Promise<MockResponse | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(mockResponseActions.setLoadingSelectedTemplateResponses(true));

  try {
    const templateResponse = await mockResponseService.create(newResponse);

    return templateResponse;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(mockResponseActions.setLoadingSelectedTemplateResponses(false));
  }

  return null;
};

const deleteTemplateResponse = (responseId: string): AppThunk<Promise<MockResponse | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(mockResponseActions.setLoadingSelectedTemplateResponses(true));

  try {
    const templateResponse = await mockResponseService.deleteResponse(responseId);

    return templateResponse;
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(mockResponseActions.setLoadingSelectedTemplateResponses(false));
  }

  return null;
};

const fetchSelectedResponse = (responseId: string): AppThunk<Promise<MockResponse | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(mockResponseActions.setLoadingSelectedResponse(true));

  try {
    const selectedResponse = await mockResponseService.getById(responseId);

    dispatch(mockResponseActions.setSelectedResponse(selectedResponse));
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(mockResponseActions.setLoadingSelectedResponse(false));
  }

  return null;
};

const updateSelectedResponse = (responseData: MockResponseData): AppThunk<Promise<MockResponse | null>> => async (dispatch: CustomDispatchType) => {
  dispatch(mockResponseActions.setLoadingSelectedResponse(true));

  try {
    const updatedSelectedResponse = await mockResponseService.update(responseData);

    dispatch(mockResponseActions.setSelectedResponse(updatedSelectedResponse));
  } catch (err: any) {
    dispatch(errorActions.setError({ message: err.response.data.message, errorStatus: err.response.status }));
  } finally {
    dispatch(mockResponseActions.setLoadingSelectedResponse(false));
  }

  return null;
};

const clearAllResponses = () => (dispatch: CustomDispatchType) => {
  dispatch(mockResponseActions.clearSelectedResponse());
  dispatch(mockResponseActions.clearSelectedTemplateResponses());
};

export const MockResponseSelector = (state: AppState) => state.mockResponse;
export const SelectedTemplateResponsesListSelector = (state: AppState) => state.mockResponse.selectedTemplateResponses;
export const SelectedTemplateResponsesLoadingSelector = (state: AppState) => state.mockResponse.loadingSelectedTemplateResponses;
export const SelectedResponseSelector = (state: AppState) => state.mockResponse.selectedResponse;
export const SelectedResponseLoadingSelector = (state: AppState) => state.mockResponse.loadingSelectedResponse;

export const mockResponseActions = mockResponseSlice.actions;

export const mockResponseThunks = {
  fetchTemplateResponses,
  createTemplateResponse,
  deleteTemplateResponse,
  fetchSelectedResponse,
  updateSelectedResponse,
  clearAllResponses,
};

export default mockResponseSlice.reducer;
